import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ArrowCircleLeftIcon } from '@heroicons/react/outline';
import { PATH } from '@/modules/Housing/routing/apartmentSetup';
import {
  requestTeamBeddingStatisticsAsync,
  setIsHideMenuAction,
  teamBeddingStatisticSelector,
} from '@/modules/Housing/redux/apartment';
import { Outlet, useParams } from 'react-router';
import { TeamNav } from '../components';
import { apartmentSetupConstants } from '@/modules/Housing/lib';
import { Loader } from '@/components/common';
import { isTeamBeddingStatisticsLoadingSelector } from '@/modules/Housing/redux/loading';

const {
  VIEW_BY_TEAM,
} = apartmentSetupConstants;

const TeamDetails = ({
  setIsHideMenu,
  requestTeamBeddingStatistics,
  teamBeddingStatistics,
  beddingStatisticsLoading,
}) => {
  const navigate = useNavigate();
  const { teamId, complexId } = useParams();

  useEffect(() => {
    requestTeamBeddingStatistics({ teamId });
  }, [requestTeamBeddingStatistics, teamId]);

  useEffect(() => {
    setIsHideMenu(true);

    return () => {
      setIsHideMenu(false);
    };
  }, [setIsHideMenu]);

  const handleClickBack = () => {
    navigate(PATH, { state: { viewOption: VIEW_BY_TEAM } });
  };

  return (
    <>
      <div className="w-full flex bg-white border-b border-gray-200 pl-8 pr-8">
        <div className="mt-4 mb-4 mr-8 w-1/5">
          <div
            onClick={handleClickBack}
            className="text-gray-600 hover:text-gray-900 inline-flex items-center hover:cursor-pointer hover:bg-white align-middle"
          >
            <ArrowCircleLeftIcon className="h-6 w-6 mr-2" aria-hidden="true" />
            <span className="text-sm">Back to Apartment team view</span>
          </div>
        </div>
        <div className="w-3/5">
          <TeamNav teamId={teamId} />
        </div>
      </div>
      <div className="grow flex max-w-full m-8">
        <div className="w-1/5 border flex flex-col mr-8 rounded-xl bg-white">
          {beddingStatisticsLoading
            ? <Loader className="mt-12" />
            : (
              <>
                <div className="mr-1 ml-1 p-6 pl-9 pr-9 border-b border-gray-200 text-lg font-semibold">
                  {`Team: ${teamBeddingStatistics?.team_name}`}
                </div>
                <div className="pl-6 pr-6">
                  <div className="mr-1 ml-1 p-3 border-b border-gray-200 text-md font-semibold">
                    Rep Information
                  </div>
                  <div className="mr-1 ml-1 p-3 text-gray-600 border-b border-gray-200 text-sm flex justify-between">
                    <div>Total unbedded reps</div>
                    <div className="font-semibold">{teamBeddingStatistics?.total_un_bedded_reps}</div>
                  </div>
                  <div className="mr-1 ml-1 p-3 text-gray-600 border-b border-gray-200 text-sm flex justify-between">
                    <div>Total bedded reps</div>
                    <div className="font-semibold">{teamBeddingStatistics?.total_bedded_reps}</div>
                  </div>
                  <div className="mr-1 ml-1 p-3 text-gray-600 border-b border-gray-200 text-sm flex justify-between">
                    <div>Total active reps</div>
                    <div className="font-semibold">{teamBeddingStatistics?.total_reps}</div>
                  </div>
                  <div className="mr-1 ml-1 p-3 text-gray-600 border-b border-gray-200 text-sm flex justify-between">
                    <div>Total unbedded service pros</div>
                    <div className="font-semibold">{teamBeddingStatistics?.total_un_bedded_service_pros}</div>
                  </div>
                  <div className="mr-1 ml-1 p-3 text-gray-600 border-b border-gray-200 text-sm flex justify-between">
                    <div>Total bedded service pros</div>
                    <div className="font-semibold">{teamBeddingStatistics?.total_bedded_service_pros}</div>
                  </div>
                  <div className="mr-1 ml-1 p-3 text-gray-600 border-b border-gray-200 text-sm flex justify-between">
                    <div>Total active service pros</div>
                    <div className="font-semibold">{teamBeddingStatistics?.total_service_pros}</div>
                  </div>
                </div>
              </>
            )}
        </div>
        <div className="w-4/5 flex flex-col border rounded-xl bg-white">
          {!complexId && <Outlet />}
        </div>
      </div>
    </>
  );
};

TeamDetails.propTypes = {
  setIsHideMenu: PropTypes.func,
  getTeamInfo: PropTypes.func,
  requestTeamBeddingStatistics: PropTypes.func,
  teamBeddingStatistics: PropTypes.shape({
    team_name: PropTypes.string,
    total_un_bedded_reps: PropTypes.number,
    total_bedded_reps: PropTypes.number,
    total_reps: PropTypes.number,
    total_un_bedded_service_pros: PropTypes.number,
    total_bedded_service_pros: PropTypes.number,
    total_service_pros: PropTypes.number,
  }),
  beddingStatisticsLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  teamBeddingStatistics: teamBeddingStatisticSelector(state),
  beddingStatisticsLoading: isTeamBeddingStatisticsLoadingSelector(state),
});

const mapDispatchToProps = {
  setIsHideMenu: setIsHideMenuAction,
  requestTeamBeddingStatistics: requestTeamBeddingStatisticsAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamDetails);
