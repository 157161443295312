export var mockupfleetRoutes = {
    _metadata: {
        success: true,
    },
    result: {
        routes: [
            {
                id: 4497004,
                service_pro_id: 518515,
                service_pro_name: 'Kody Codner',
                external_id: 'AP003996',
                avatar_placement: {
                    lat: 40.570412,
                    lng: -111.941147,
                },
                tracking_data: {
                    driver_location: {
                        lat: 40.570412,
                        lng: -111.941147,
                        timestamp: '',
                    },
                    vehicle_location: {
                        lat: 40.570412,
                        lng: -111.941147,
                        timestamp: '',
                    },
                    vehicle_speed: 100,
                },
                appointments: [
                    {
                        id: 27271909,
                        start: '2023-11-10 08:21:28',
                        end: '2023-11-10 08:44:28',
                        lat: 40.570412,
                        lng: -111.941147,
                    },
                    {
                        id: 27271917,
                        start: '2023-11-10 08:48:14',
                        end: '2023-11-10 09:11:14',
                        lat: 40.56897,
                        lng: -111.949997,
                    },
                    {
                        id: 27271910,
                        start: '2023-11-10 09:15:25',
                        end: '2023-11-10 09:38:25',
                        lat: 40.570789,
                        lng: -111.963013,
                    },
                    {
                        id: 27271925,
                        start: '2023-11-10 09:54:28',
                        end: '2023-11-10 10:17:28',
                        lat: 40.568817,
                        lng: -111.963943,
                    },
                    {
                        id: 27271918,
                        start: '2023-11-10 10:22:17',
                        end: '2023-11-10 10:55:17',
                        lat: 40.557003,
                        lng: -111.969749,
                    },
                    {
                        id: 27271902,
                        start: '2023-11-10 11:31:03',
                        end: '2023-11-10 11:54:03',
                        lat: 40.563709,
                        lng: -112.000481,
                    },
                    {
                        id: 27271926,
                        start: '2023-11-10 11:57:12',
                        end: '2023-11-10 12:28:12',
                        lat: 40.558395,
                        lng: -112.009277,
                    },
                    {
                        id: 27271907,
                        start: '2023-11-10 12:32:40',
                        end: '2023-11-10 12:55:40',
                        lat: 40.544064,
                        lng: -112.011894,
                    },
                    {
                        id: 27272342,
                        start: '2023-11-10 13:15:00',
                        end: '2023-11-10 14:00:00',
                        lat: 40.542999,
                        lng: -112.031967,
                    },
                    {
                        id: 27272374,
                        start: '2023-11-10 14:00:00',
                        end: '2023-11-10 14:20:00',
                        lat: 40.542999,
                        lng: -112.031967,
                    },
                    {
                        id: 27272376,
                        start: '2023-11-10 14:28:00',
                        end: '2023-11-10 14:51:00',
                        lat: 40.584663,
                        lng: -112.021461,
                    },
                    {
                        id: 27272380,
                        start: '2023-11-10 14:53:58',
                        end: '2023-11-10 15:10:58',
                        lat: 40.585033,
                        lng: -112.015526,
                    },
                    {
                        id: 27271900,
                        start: '2023-11-10 15:14:15',
                        end: '2023-11-10 15:37:15',
                        lat: 40.592815,
                        lng: -112.004211,
                    },
                    {
                        id: 27271898,
                        start: '2023-11-10 15:40:19',
                        end: '2023-11-10 16:03:19',
                        lat: 40.585861,
                        lng: -111.99736,
                    },
                    {
                        id: 27271916,
                        start: '2023-11-10 16:04:48',
                        end: '2023-11-10 16:26:48',
                        lat: 40.583324,
                        lng: -111.995056,
                    },
                    {
                        id: 27271912,
                        start: '2023-11-10 16:28:44',
                        end: '2023-11-10 16:51:44',
                        lat: 40.584805,
                        lng: -111.989578,
                    },
                    {
                        id: 27271920,
                        start: '2023-11-10 16:54:37',
                        end: '2023-11-10 17:17:37',
                        lat: 40.594376,
                        lng: -111.984268,
                    },
                    {
                        id: 27271914,
                        start: '2023-11-10 17:23:51',
                        end: '2023-11-10 17:46:51',
                        lat: 40.610313,
                        lng: -112.016479,
                    },
                    {
                        id: 27271906,
                        start: '2023-11-10 17:48:25',
                        end: '2023-11-10 18:12:25',
                        lat: 40.613758,
                        lng: -112.019897,
                    },
                    {
                        id: 27271905,
                        start: '2023-11-10 18:21:40',
                        end: '2023-11-10 18:44:40',
                        lat: 40.625309,
                        lng: -111.97081,
                    },
                    {
                        id: 26924206,
                        start: '2023-11-10 18:51:15',
                        end: '2023-11-10 19:12:15',
                        lat: 40.626743,
                        lng: -111.926865,
                    },
                ],
                area: [
                    {
                        lat: 40.542999,
                        lng: -112.031967,
                    },
                    {
                        lat: 40.544064,
                        lng: -112.011894,
                    },
                    {
                        lat: 40.557003,
                        lng: -111.969749,
                    },
                    {
                        lat: 40.570412,
                        lng: -111.941147,
                    },
                    {
                        lat: 40.626743,
                        lng: -111.926865,
                    },
                    {
                        lat: 40.625309,
                        lng: -111.97081,
                    },
                    {
                        lat: 40.613758,
                        lng: -112.019897,
                    },
                    {
                        lat: 40.542999,
                        lng: -112.031967,
                    },
                ],
                statistics: {
                    average_drive_miles: 3.86,
                    average_drive_time_minutes: 6,
                    total_appointments: 11,
                    total_drive_miles: 42.46,
                    total_drive_time_minutes: 73,
                    total_service_time_minutes: 256,
                },
                geometry: '_p~iF~ps|U_ulLnnqC_mqNvxq`@',
                actual_stats: {
                    at_risk: false,
                    completion_percentage: 65,
                },
            },
        ],
        summary: {
            total_routes: 3,
            total_appointments: 33,
            total_drive_time_minutes: 146,
            total_drive_miles: 72.79,
            total_service_time_minutes: 806,
            appointments_per_gallon: 0,
            total_routes_actual: 3,
            total_appointments_actual: 0,
            total_drive_time_minutes_actual: 240,
            total_drive_miles_actual: 118.81,
            total_service_time_minutes_actual: 0,
            appointments_per_gallon_actual: 0,
        },
    },
};
export var mockupSummary = {
    total_routes: 3,
    total_appointments: 33,
    total_drive_time_minutes: 146,
    total_drive_miles: 72.79,
    total_service_time_minutes: 806,
    appointments_per_gallon: 1,
    total_routes_actual: 3,
    total_appointments_actual: 0,
    total_drive_time_minutes_actual: 240,
    total_drive_miles_actual: 118.81,
    total_service_time_minutes_actual: 0,
    appointments_per_gallon_actual: 2,
};
export var mockupStatistics = {
    average_drive_miles: 3.86,
    average_drive_time_minutes: 6,
    total_appointments: 11,
    total_drive_miles: 42.46,
    total_drive_time_minutes: 73,
    total_service_time_minutes: 256,
};
export var mockupRegions = {
    _metadata: {
        success: true
    },
    result: [
        {
            id: 1,
            name: 'Central',
            offices: [
                {
                    id: 14,
                    name: 'Kansas City',
                    region: 'Central',
                    address: '10675 Widmer Rd',
                    city: 'LENEXA',
                    state: 'KS',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Chicago',
                    },
                    timezone_name: 'Central Daylight Time',
                    location: {
                        lat: 38.93512885531407,
                        lng: -94.74680082031372,
                    },
                },
                {
                    id: 27,
                    name: 'Salt Lake City',
                    region: 'Central',
                    address: '548 W 8360 S',
                    city: 'Sandy',
                    state: 'UT',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Denver',
                    },
                    timezone_name: 'Mountain Daylight Time',
                    location: {
                        lat: 40.59966840187996,
                        lng: -111.90660015519408,
                    },
                },
                {
                    id: 30,
                    name: 'Saint Louis',
                    region: 'Central',
                    address: '10747 INDIAN HEAD INDUSTRIAL BLVD',
                    city: 'St Louis',
                    state: 'MO',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Chicago',
                    },
                    timezone_name: 'Central Daylight Time',
                    location: {
                        lat: 38.689790881975725,
                        lng: -90.3975311215154,
                    },
                },
                {
                    id: 32,
                    name: 'Denver',
                    region: 'Central',
                    address: '6050 E 58th Ave Suite E',
                    city: 'Commerce City',
                    state: 'CO',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Denver',
                    },
                    timezone_name: 'Mountain Daylight Time',
                    location: {
                        lat: 39.80132653896089,
                        lng: -104.91801460056722,
                    },
                },
                {
                    id: 71,
                    name: 'Des Moines',
                    region: 'Central',
                    address: '101 Jordan Creek Pkwy',
                    city: 'West Des Moines',
                    state: 'IA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Chicago',
                    },
                    timezone_name: 'Central Daylight Time',
                    location: {
                        lat: 41.569938925661404,
                        lng: -93.80416280950818,
                    },
                },
                {
                    id: 108,
                    name: 'Omaha',
                    region: 'Central',
                    address: '11016 Mockingbird Ln Ste 105',
                    city: 'Omaha',
                    state: 'NE',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Chicago',
                    },
                    timezone_name: 'Central Daylight Time',
                    location: {
                        lat: 41.20804396313714,
                        lng: -96.08527866059244,
                    },
                },
                {
                    id: 109,
                    name: 'Oklahoma City',
                    region: 'Central',
                    address: '6125 W Reno Ave Ste 700',
                    city: 'Oklahoma City',
                    state: 'OK',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Chicago',
                    },
                    timezone_name: 'Central Daylight Time',
                    location: {
                        lat: 35.46504455232052,
                        lng: -97.62404642201079,
                    },
                },
                {
                    id: 39,
                    name: 'Corporate',
                    region: 'Central',
                    address: '251 W River Park Drive 200',
                    city: 'Provo',
                    state: 'UT',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Denver',
                    },
                    timezone_name: 'Mountain Daylight Time',
                    location: {
                        lat: 40.3031062,
                        lng: -111.6616996,
                    },
                },
            ],
            boundary: [
                {
                    lat: 35.46504455232052,
                    lng: -97.62404642201079,
                },
                {
                    lat: 35.46504455232052,
                    lng: -97.62404642201079,
                },
                {
                    lat: 38.689790881975725,
                    lng: -90.3975311215154,
                },
                {
                    lat: 41.569938925661404,
                    lng: -93.80416280950818,
                },
                {
                    lat: 40.59966840187996,
                    lng: -111.90660015519408,
                },
                {
                    lat: 40.3031062,
                    lng: -111.6616996,
                },
                {
                    lat: 35.46504455232052,
                    lng: -97.62404642201079,
                },
            ],
        },
        {
            id: 2,
            name: 'Great Lakes',
            offices: [
                {
                    id: 6,
                    name: 'Chicago',
                    region: 'Great Lakes',
                    address: '245 W Roosevelt Rd Building 9 Ste 58',
                    city: 'West Chicago',
                    state: 'IL',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Chicago',
                    },
                    timezone_name: 'Central Daylight Time',
                    location: {
                        lat: 41.86780789514579,
                        lng: -88.20770102963915,
                    },
                },
                {
                    id: 18,
                    name: 'Milwaukee',
                    region: 'Great Lakes',
                    address: '17919 W Lincoln Ave',
                    city: 'Milwaukee',
                    state: 'WI',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Chicago',
                    },
                    timezone_name: 'Central Daylight Time',
                    location: {
                        lat: 43.00205780946535,
                        lng: -88.13638022442868,
                    },
                },
                {
                    id: 19,
                    name: 'Minneapolis',
                    region: 'Great Lakes',
                    address: '5251 W 73rd St Unit C',
                    city: 'Edina',
                    state: 'MN',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Chicago',
                    },
                    timezone_name: 'Central Daylight Time',
                    location: {
                        lat: 44.87008986497,
                        lng: -93.35797065451543,
                    },
                },
                {
                    id: 34,
                    name: 'Detroit',
                    region: 'Great Lakes',
                    address: '37728 Hills Tech Dr',
                    city: 'Farmington',
                    state: 'MI',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 42.49260833943768,
                        lng: -83.42068222389734,
                    },
                },
                {
                    id: 77,
                    name: 'Grand Rapids',
                    region: 'Great Lakes',
                    address: '4671 Alpine Ave NW',
                    city: 'Comstock Park',
                    state: 'MI',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 43.04874976241352,
                        lng: -85.68976758844092,
                    },
                },
            ],
            boundary: [
                {
                    lat: 41.86780789514579,
                    lng: -88.20770102963915,
                },
                {
                    lat: 41.86780789514579,
                    lng: -88.20770102963915,
                },
                {
                    lat: 42.49260833943768,
                    lng: -83.42068222389734,
                },
                {
                    lat: 43.04874976241352,
                    lng: -85.68976758844092,
                },
                {
                    lat: 44.87008986497,
                    lng: -93.35797065451543,
                },
                {
                    lat: 41.86780789514579,
                    lng: -88.20770102963915,
                },
            ],
        },
        {
            id: 3,
            name: 'Gulf Coast',
            offices: [
                {
                    id: 3,
                    name: 'Austin',
                    region: 'Gulf Coast',
                    address: '8204 N LAMAR BLVD B-14',
                    city: 'Austin',
                    state: 'TX',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Chicago',
                    },
                    timezone_name: 'Central Daylight Time',
                    location: {
                        lat: 30.351305579189788,
                        lng: -97.70943845704998,
                    },
                },
                {
                    id: 9,
                    name: 'Dallas',
                    region: 'Gulf Coast',
                    address: '2030 Ave G Ste 1100',
                    city: 'Plano',
                    state: 'TX',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Chicago',
                    },
                    timezone_name: 'Central Daylight Time',
                    location: {
                        lat: 33.027851547175736,
                        lng: -96.70435474220977,
                    },
                },
                {
                    id: 12,
                    name: 'Sugar Land',
                    region: 'Gulf Coast',
                    address: '8800 Jameel RD STE 100B',
                    city: 'Houston',
                    state: 'TX',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Chicago',
                    },
                    timezone_name: 'Central Daylight Time',
                    location: {
                        lat: 29.857864671437667,
                        lng: -95.52605919482464,
                    },
                },
                {
                    id: 28,
                    name: 'San Antonio',
                    region: 'Gulf Coast',
                    address: '1049 E Nakoma Dr',
                    city: 'San Antonio',
                    state: 'TX',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Chicago',
                    },
                    timezone_name: 'Central Daylight Time',
                    location: {
                        lat: 29.54619243391219,
                        lng: -98.47907914682641,
                    },
                },
                {
                    id: 41,
                    name: 'Fort Worth',
                    region: 'Gulf Coast',
                    address: '2569 Gravel Drive Building 4',
                    city: 'Fort Worth',
                    state: 'TX',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Chicago',
                    },
                    timezone_name: 'Central Daylight Time',
                    location: {
                        lat: 32.79630061652126,
                        lng: -97.21823264588843,
                    },
                },
                {
                    id: 82,
                    name: 'Houston',
                    region: 'Gulf Coast',
                    address: '8800 Jameel Road Ste 100B',
                    city: 'Houston',
                    state: 'TX',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Chicago',
                    },
                    timezone_name: 'Central Daylight Time',
                    location: {
                        lat: 29.8578445949927,
                        lng: -95.52606450039546,
                    },
                },
            ],
            boundary: [
                {
                    lat: 29.54619243391219,
                    lng: -98.47907914682641,
                },
                {
                    lat: 29.54619243391219,
                    lng: -98.47907914682641,
                },
                {
                    lat: 29.8578445949927,
                    lng: -95.52606450039546,
                },
                {
                    lat: 29.857864671437667,
                    lng: -95.52605919482464,
                },
                {
                    lat: 33.027851547175736,
                    lng: -96.70435474220977,
                },
                {
                    lat: 32.79630061652126,
                    lng: -97.21823264588843,
                },
                {
                    lat: 29.54619243391219,
                    lng: -98.47907914682641,
                },
            ],
        },
        {
            id: 4,
            name: 'Mid-Atlantic',
            offices: [
                {
                    id: 4,
                    name: 'Baltimore',
                    region: 'Mid-Atlantic',
                    address: '9090 Junction Dr Suite 1',
                    city: 'Annapolis Junction',
                    state: 'MD',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 39.12374222910697,
                        lng: -76.80003874200659,
                    },
                },
                {
                    id: 10,
                    name: 'Alexandria',
                    region: 'Mid-Atlantic',
                    address: '4511 Daly Dr Ste B',
                    city: 'Chantilly',
                    state: 'VA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 38.8838675559221,
                        lng: -77.44309016300716,
                    },
                },
                {
                    id: 22,
                    name: 'Philadelphia',
                    region: 'Mid-Atlantic',
                    address: '3448 Progress Dr Unit F',
                    city: 'Bensalem',
                    state: 'PA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 40.08558841916625,
                        lng: -74.92740191225137,
                    },
                },
                {
                    id: 24,
                    name: 'Pittsburgh',
                    region: 'Mid-Atlantic',
                    address: '502 Bursca Dr',
                    city: 'Bridgeville',
                    state: 'PA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 40.34238734699366,
                        lng: -80.1125592977132,
                    },
                },
                {
                    id: 47,
                    name: 'Allentown',
                    region: 'Mid-Atlantic',
                    address: '1150 Glenlivet Dr Ste B-28',
                    city: 'Allentown',
                    state: 'PA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 40.589839476721146,
                        lng: -75.62831473422833,
                    },
                },
                {
                    id: 80,
                    name: 'Harrisburg',
                    region: 'Mid-Atlantic',
                    address: '2009 Shady Oak Dr',
                    city: 'Mount Joy',
                    state: 'PA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 40.12770150184229,
                        lng: -76.55690447364717,
                    },
                },
                {
                    id: 88,
                    name: 'Virginia Beach',
                    region: 'Mid-Atlantic',
                    address: '2551 Eltham Ave Suite F',
                    city: 'Norfolk',
                    state: 'VA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 36.87759201285307,
                        lng: -76.22244401700641,
                    },
                },
                {
                    id: 100,
                    name: 'Richmond',
                    region: 'Mid-Atlantic',
                    address: '2020 E Franklin St Apt 402',
                    city: 'Richmond',
                    state: 'VA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 37.53230678931965,
                        lng: -77.4241190698695,
                    },
                },
            ],
            boundary: [
                {
                    lat: 36.87759201285307,
                    lng: -76.22244401700641,
                },
                {
                    lat: 36.87759201285307,
                    lng: -76.22244401700641,
                },
                {
                    lat: 40.08558841916625,
                    lng: -74.92740191225137,
                },
                {
                    lat: 40.589839476721146,
                    lng: -75.62831473422833,
                },
                {
                    lat: 40.34238734699366,
                    lng: -80.1125592977132,
                },
                {
                    lat: 37.53230678931965,
                    lng: -77.4241190698695,
                },
                {
                    lat: 36.87759201285307,
                    lng: -76.22244401700641,
                },
            ],
        },
        {
            id: 5,
            name: 'Midwest',
            offices: [
                {
                    id: 2,
                    name: 'Columbus',
                    region: 'Midwest',
                    address: '733 Lakeview Plaza Blvd E',
                    city: 'Columbus',
                    state: 'OH',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 40.1119183360268,
                        lng: -82.99692601118139,
                    },
                },
                {
                    id: 7,
                    name: 'Cincinnati',
                    region: 'Midwest',
                    address: '4722 Interstate Dr Suite K',
                    city: 'Cincinnati',
                    state: 'OH',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 39.30905616254363,
                        lng: -84.46795495823466,
                    },
                },
                {
                    id: 8,
                    name: 'Cleveland',
                    region: 'Midwest',
                    address: '2525 E Royalton Rd Unit D',
                    city: 'Broadview Heights',
                    state: 'OH',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 41.31464953822338,
                        lng: -81.66902120027443,
                    },
                },
                {
                    id: 13,
                    name: 'Indianapolis',
                    region: 'Midwest',
                    address: '8857 BOEHNING LANE',
                    city: 'INDIANAPOLIS',
                    state: 'IN',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 39.79932184217493,
                        lng: -86.0116609199139,
                    },
                },
                {
                    id: 20,
                    name: 'Nashville',
                    region: 'Midwest',
                    address: '109 SPACE PARK SOUTH',
                    city: 'Nashville',
                    state: 'TN',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Chicago',
                    },
                    timezone_name: 'Central Daylight Time',
                    location: {
                        lat: 36.09787750335761,
                        lng: -86.70710554107488,
                    },
                },
                {
                    id: 60,
                    name: 'Chattanooga',
                    region: 'Midwest',
                    address: '109 SPACE PARK SOUTH',
                    city: 'Nasvhille',
                    state: 'TN',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 36.097881199770015,
                        lng: -86.70710613737045,
                    },
                },
                {
                    id: 91,
                    name: 'Toledo',
                    region: 'Midwest',
                    address: '1001 N Holland Sylvania Rd',
                    city: 'Toledo',
                    state: 'OH',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 41.65058541932706,
                        lng: -83.68308496967956,
                    },
                },
                {
                    id: 115,
                    name: 'Memphis',
                    region: 'Midwest',
                    address: '109 SPACE PARK SOUTH',
                    city: 'Nashville',
                    state: 'TN',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Chicago',
                    },
                    timezone_name: 'Central Daylight Time',
                    location: {
                        lat: 36.097879997056445,
                        lng: -86.70710629798614,
                    },
                },
                {
                    id: 121,
                    name: 'Louisville',
                    region: 'Midwest',
                    address: '11481 Blankenbaker Access Dr',
                    city: 'Louisville',
                    state: 'KY',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 38.19752336866643,
                        lng: -85.55484597949463,
                    },
                },
            ],
            boundary: [
                {
                    lat: 36.09787750335761,
                    lng: -86.70710554107488,
                },
                {
                    lat: 36.09787750335761,
                    lng: -86.70710554107488,
                },
                {
                    lat: 41.31464953822338,
                    lng: -81.66902120027443,
                },
                {
                    lat: 41.65058541932706,
                    lng: -83.68308496967956,
                },
                {
                    lat: 39.79932184217493,
                    lng: -86.0116609199139,
                },
                {
                    lat: 36.097881199770015,
                    lng: -86.70710613737045,
                },
                {
                    lat: 36.097879997056445,
                    lng: -86.70710629798614,
                },
                {
                    lat: 36.09787750335761,
                    lng: -86.70710554107488,
                },
            ],
        },
        {
            id: 6,
            name: 'Northeast',
            offices: [
                {
                    id: 33,
                    name: 'Princeton',
                    region: 'Northeast',
                    address: '05 Ilene Ct Suite 2 and 4',
                    city: 'Hillsborough',
                    state: 'NJ',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 40.49048222723885,
                        lng: -74.6437631890458,
                    },
                },
                {
                    id: 35,
                    name: 'Newark',
                    region: 'Northeast',
                    address: '1719 NJ-10 Suite 310',
                    city: 'Parsippany',
                    state: 'NJ',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 40.8404130114985,
                        lng: -74.46016945551281,
                    },
                },
                {
                    id: 36,
                    name: 'Long Island',
                    region: 'Northeast',
                    address: '100 E Jefryn Blvd Ste Q',
                    city: 'Deer Park',
                    state: 'NY',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 40.75865026520998,
                        lng: -73.2983699713817,
                    },
                },
                {
                    id: 37,
                    name: 'White Plains',
                    region: 'Northeast',
                    address: '7 W Cross Street',
                    city: 'Hawthorne',
                    state: 'NY',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 41.106583897750504,
                        lng: -73.7969884351727,
                    },
                },
                {
                    id: 79,
                    name: 'Boston',
                    region: 'Northeast',
                    address: '125 Washington St STE 3',
                    city: 'Hingham',
                    state: 'MA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 42.07771870437433,
                        lng: -71.28476644731718,
                    },
                },
                {
                    id: 131,
                    name: 'Dover',
                    region: 'Northeast',
                    address: '3448 Progress Dr Unit F',
                    city: 'Bensalem',
                    state: 'PA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 40.08558603274481,
                        lng: -74.92739612380211,
                    },
                },
            ],
            boundary: [
                {
                    lat: 40.08558603274481,
                    lng: -74.92739612380211,
                },
                {
                    lat: 40.08558603274481,
                    lng: -74.92739612380211,
                },
                {
                    lat: 40.75865026520998,
                    lng: -73.2983699713817,
                },
                {
                    lat: 42.07771870437433,
                    lng: -71.28476644731718,
                },
                {
                    lat: 41.106583897750504,
                    lng: -73.7969884351727,
                },
                {
                    lat: 40.8404130114985,
                    lng: -74.46016945551281,
                },
                {
                    lat: 40.08558603274481,
                    lng: -74.92739612380211,
                },
            ],
        },
        {
            id: 7,
            name: 'Southeast',
            offices: [
                {
                    id: 1,
                    name: 'Atlanta',
                    region: 'Southeast',
                    address: '1960 Parker Ct Suite B',
                    city: 'Stone Mountain',
                    state: 'GA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 33.827960984160846,
                        lng: -84.10652351727147,
                    },
                },
                {
                    id: 11,
                    name: 'Fort Lauderdale',
                    region: 'Southeast',
                    address: '11860 W State Rd 84 Suite B13',
                    city: 'Davie',
                    state: 'FL',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 26.111176955276644,
                        lng: -80.30678798650136,
                    },
                },
                {
                    id: 42,
                    name: 'Tampa',
                    region: 'Southeast',
                    address: '12919 N Nebraska Ave',
                    city: 'Tampa',
                    state: 'FL',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 28.064678324443484,
                        lng: -82.45069396176261,
                    },
                },
                {
                    id: 43,
                    name: 'Raleigh',
                    region: 'Southeast',
                    address: '233 E Johnson St Ste L',
                    city: 'Cary',
                    state: 'NC',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 35.793141992462864,
                        lng: -78.7758502142174,
                    },
                },
                {
                    id: 44,
                    name: 'Orlando',
                    region: 'Southeast',
                    address: '4597 Parkbreeze court',
                    city: 'Orlando',
                    state: 'FL',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 28.59773103056911,
                        lng: -81.42120762490164,
                    },
                },
                {
                    id: 58,
                    name: 'Charlotte',
                    region: 'Southeast',
                    address: '4215 Stuart Andrew Blvd Suite D',
                    city: 'Charlotte',
                    state: 'NC',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 35.19019548969358,
                        lng: -80.88782069114427,
                    },
                },
                {
                    id: 78,
                    name: 'Greensboro',
                    region: 'Southeast',
                    address: '2900 N Main St 105',
                    city: 'High Point',
                    state: 'NC',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 35.996039199124134,
                        lng: -80.03214818528862,
                    },
                },
                {
                    id: 86,
                    name: 'Wilmington',
                    region: 'Southeast',
                    address: '1545 S College Rd',
                    city: 'Wilmington',
                    state: 'NC',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 34.20857827781655,
                        lng: -77.88676346810058,
                    },
                },
                {
                    id: 112,
                    name: 'Jacksonville',
                    region: 'Southeast',
                    address: '6900 Philips Hwy',
                    city: 'Jacksonville',
                    state: 'FL',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/New_York',
                    },
                    timezone_name: 'Eastern Daylight Time',
                    location: {
                        lat: 30.24686081089476,
                        lng: -81.60401832372413,
                    },
                },
            ],
            boundary: [
                {
                    lat: 26.111176955276644,
                    lng: -80.30678798650136,
                },
                {
                    lat: 26.111176955276644,
                    lng: -80.30678798650136,
                },
                {
                    lat: 34.20857827781655,
                    lng: -77.88676346810058,
                },
                {
                    lat: 35.793141992462864,
                    lng: -78.7758502142174,
                },
                {
                    lat: 35.996039199124134,
                    lng: -80.03214818528862,
                },
                {
                    lat: 33.827960984160846,
                    lng: -84.10652351727147,
                },
                {
                    lat: 28.064678324443484,
                    lng: -82.45069396176261,
                },
                {
                    lat: 26.111176955276644,
                    lng: -80.30678798650136,
                },
            ],
        },
        {
            id: 8,
            name: 'Northwest',
            offices: [
                {
                    id: 5,
                    name: 'Bay Area',
                    region: 'Northwest',
                    address: '488 Lindbergh Ave',
                    city: 'Livermore',
                    state: 'CA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Los_Angeles',
                    },
                    timezone_name: 'Pacific Daylight Time',
                    location: {
                        lat: 37.697133150996585,
                        lng: -121.81564207358885,
                    },
                },
                {
                    id: 25,
                    name: 'Portland',
                    region: 'Northwest',
                    address: '10260 SW Nimbus Ave M4',
                    city: 'Portland',
                    state: 'OR',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Los_Angeles',
                    },
                    timezone_name: 'Pacific Daylight Time',
                    location: {
                        lat: 45.44585940925855,
                        lng: -122.78599915627863,
                    },
                },
                {
                    id: 26,
                    name: 'Sacramento',
                    region: 'Northwest',
                    address: '4700 Northgate Blvd STE 135',
                    city: 'Sacramento',
                    state: 'CA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Los_Angeles',
                    },
                    timezone_name: 'Pacific Daylight Time',
                    location: {
                        lat: 38.65403898830475,
                        lng: -121.47748747225464,
                    },
                },
                {
                    id: 31,
                    name: 'Seattle',
                    region: 'Northwest',
                    address: '941 Industry Dr',
                    city: 'Tukwila',
                    state: 'WA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Los_Angeles',
                    },
                    timezone_name: 'Pacific Daylight Time',
                    location: {
                        lat: 47.44940360427853,
                        lng: -122.24885439354455,
                    },
                },
                {
                    id: 57,
                    name: 'San Jose',
                    region: 'Northwest',
                    address: '48531 Warm Springs Blvd 410',
                    city: 'Fremont',
                    state: 'CA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Los_Angeles',
                    },
                    timezone_name: 'Pacific Daylight Time',
                    location: {
                        lat: 37.467587898328546,
                        lng: -121.91810305986806,
                    },
                },
                {
                    id: 95,
                    name: 'Seattle South',
                    region: 'Northwest',
                    address: '941 Industry Drive',
                    city: 'Tukwila',
                    state: 'WA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Los_Angeles',
                    },
                    timezone_name: 'Pacific Daylight Time',
                    location: {
                        lat: 47.44942624317874,
                        lng: -122.24885799912288,
                    },
                },
            ],
            boundary: [
                {
                    lat: 37.467587898328546,
                    lng: -121.91810305986806,
                },
                {
                    lat: 37.467587898328546,
                    lng: -121.91810305986806,
                },
                {
                    lat: 37.697133150996585,
                    lng: -121.81564207358885,
                },
                {
                    lat: 38.65403898830475,
                    lng: -121.47748747225464,
                },
                {
                    lat: 47.44940360427853,
                    lng: -122.24885439354455,
                },
                {
                    lat: 47.44942624317874,
                    lng: -122.24885799912288,
                },
                {
                    lat: 45.44585940925855,
                    lng: -122.78599915627863,
                },
                {
                    lat: 37.467587898328546,
                    lng: -121.91810305986806,
                },
            ],
        },
        {
            id: 9,
            name: 'Southwest',
            offices: [
                {
                    id: 15,
                    name: 'Las Vegas',
                    region: 'Southwest',
                    address: '5190 S Valley View Blvd 106',
                    city: 'Las Vegas',
                    state: 'NV',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Los_Angeles',
                    },
                    timezone_name: 'Pacific Daylight Time',
                    location: {
                        lat: 36.09582999560925,
                        lng: -115.18924152923714,
                    },
                },
                {
                    id: 16,
                    name: 'Los Angeles',
                    region: 'Southwest',
                    address: '21430 Strathern St STE N',
                    city: 'Canoga Park',
                    state: 'CA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Los_Angeles',
                    },
                    timezone_name: 'Pacific Daylight Time',
                    location: {
                        lat: 34.21494795970276,
                        lng: -118.5986532479067,
                    },
                },
                {
                    id: 21,
                    name: 'Orange County',
                    region: 'Southwest',
                    address: '1630 S SUNKIST ST UNIT I',
                    city: 'ANAHEIM',
                    state: 'CA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Los_Angeles',
                    },
                    timezone_name: 'Pacific Daylight Time',
                    location: {
                        lat: 33.807300363741994,
                        lng: -117.88474750287807,
                    },
                },
                {
                    id: 23,
                    name: 'Phoenix',
                    region: 'Southwest',
                    address: '5450 E Washington St STE 1',
                    city: 'Phoenix',
                    state: 'AZ',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Phoenix',
                    },
                    timezone_name: 'Mountain Standard Time',
                    location: {
                        lat: 33.44704995796889,
                        lng: -111.96329931437015,
                    },
                },
                {
                    id: 29,
                    name: 'San Diego',
                    region: 'Southwest',
                    address: '8145 Ronson Road Suite A',
                    city: 'San Diego',
                    state: 'CA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Los_Angeles',
                    },
                    timezone_name: 'Pacific Daylight Time',
                    location: {
                        lat: 32.8288452342274,
                        lng: -117.1472459642373,
                    },
                },
                {
                    id: 46,
                    name: 'Corona',
                    region: 'Southwest',
                    address: '109 N Maple St Unit K',
                    city: 'Corona',
                    state: 'CA',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Los_Angeles',
                    },
                    timezone_name: 'Pacific Daylight Time',
                    location: {
                        lat: 33.883063565404896,
                        lng: -117.60264971687681,
                    },
                },
                {
                    id: 90,
                    name: 'Tucson',
                    region: 'Southwest',
                    address: '5450 E Washington St Ste 1',
                    city: 'Phoenix',
                    state: 'AZ',
                    timezone: {
                        timezone_type: 3,
                        timezone: 'America/Phoenix',
                    },
                    timezone_name: 'Mountain Standard Time',
                    location: {
                        lat: 33.447053206291365,
                        lng: -111.96330055798424,
                    },
                },
            ],
            boundary: [
                {
                    lat: 32.8288452342274,
                    lng: -117.1472459642373,
                },
                {
                    lat: 32.8288452342274,
                    lng: -117.1472459642373,
                },
                {
                    lat: 33.44704995796889,
                    lng: -111.96329931437015,
                },
                {
                    lat: 33.447053206291365,
                    lng: -111.96330055798424,
                },
                {
                    lat: 36.09582999560925,
                    lng: -115.18924152923714,
                },
                {
                    lat: 34.21494795970276,
                    lng: -118.5986532479067,
                },
                {
                    lat: 32.8288452342274,
                    lng: -117.1472459642373,
                },
            ],
        },
    ]
};
