var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useContext, useMemo, useState } from 'react';
import { GoogleMap, Polygon, Polyline, OverlayViewF } from '@react-google-maps/api';
import { ROUTING_COLORS } from 'constants/map';
import { MapType } from 'interface';
import { AppContext } from 'pages/MapView';
import { DefaultAvatar } from 'icons';
import VehicleAvatar from 'assets/image/aptive-sp-vehicle.png';
import { decode } from '@mapbox/polyline';
import styles from './index.module.scss';
import MapAdvancedMarker from 'components/MapAdvancedMarker';
var containerStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '0.5rem',
};
var Map = (function (_a) {
    var mapType = _a.mapType, mapData = _a.mapData, routeLayers = _a.routeLayers, center = _a.center, zoom = _a.zoom;
    var _b = useContext(AppContext), isGoogleMapAPILoaded = _b.isGoogleMapAPILoaded, googleMapAPILoadError = _b.googleMapAPILoadError;
    var _c = useState(), map = _c[0], setMap = _c[1];
    var activePolygonIndex = useRef();
    var handleLoadError = function (error) {
        console.error('Error loading Google Maps:', error);
    };
    var onLoadMap = function (map) {
        setMap(map);
    };
    var onClickPolygon = function (index) {
        activePolygonIndex.current = index;
    };
    var generatePolygonOption = function (routeIndex) {
        var polygonOption = {
            fillOpacity: 0.24,
            fillColor: ROUTING_COLORS[routeIndex % ROUTING_COLORS.length].primary,
            strokeColor: ROUTING_COLORS[routeIndex % ROUTING_COLORS.length].primary,
            strokeWeight: 2,
        };
        return polygonOption;
    };
    var generateLineSymbol = function (routeIndex) {
        return {
            path: 'M 0,-0.5 0,0.5',
            strokeOpacity: 1,
            scale: 2.2,
            strokeColor: ROUTING_COLORS[routeIndex % ROUTING_COLORS.length].primary,
        };
    };
    var generateVehicle = function () {
        return (_jsx("div", __assign({ className: "".concat(styles.vehicle, " flex items-center justify-center") }, { children: _jsx("img", { src: VehicleAvatar, alt: "VehicleAvatar" }) })));
    };
    var generateAvatar = function (routeIndex, avatar) {
        var avatarBorderStyle = {
            border: "2px solid ".concat(ROUTING_COLORS[routeIndex % ROUTING_COLORS.length].primary),
        };
        return (_jsx(_Fragment, { children: avatar ? (_jsx("img", { className: "".concat(styles.avatar), src: "data:image/png;base64,".concat(avatar), alt: "", style: avatarBorderStyle })) : (_jsx("div", __assign({ className: "".concat(styles.avatar, " flex items-center justify-center bg-white"), style: avatarBorderStyle }, { children: _jsx(DefaultAvatar, {}) }))) }));
    };
    var routes = useMemo(function () {
        var _a, _b;
        return (_b = (_a = mapData === null || mapData === void 0 ? void 0 : mapData.routes) === null || _a === void 0 ? void 0 : _a.map(function (route, routeIndex) {
            var _a;
            return ({
                polygon: {
                    area: route.area,
                    options: generatePolygonOption(routeIndex),
                },
                polyline: {
                    path: (route.geometry ? decode(route.geometry) : []).map(function (_a) {
                        var lat = _a[0], lng = _a[1];
                        return ({ lat: lat, lng: lng });
                    }),
                    options: {
                        strokeOpacity: 0,
                        icons: [
                            {
                                icon: generateLineSymbol(routeIndex),
                                offset: '0',
                                repeat: '6px',
                            },
                        ],
                        map: map,
                        visible: true
                    }
                },
                homes: route.appointments.map(function (appointment, appointmentIndex) { return ({
                    map: map,
                    zIndex: 1,
                    position: { lat: appointment.lat, lng: appointment.lng },
                    content: {
                        background: ROUTING_COLORS[routeIndex % ROUTING_COLORS.length].primary,
                        borderColor: 'white',
                        glyph: appointmentIndex === 0
                            ? (_jsx("svg", __assign({ width: "10", height: "10", viewBox: "0 0 10 10", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M5.42446 0.375931C5.19015 0.141617 4.81025 0.141617 4.57593 0.375931L0.375931 4.57593C0.141617 4.81025 0.141617 5.19015 0.375931 5.42446C0.610246 5.65877 0.990145 5.65877 1.22446 5.42446L1.4002 5.24872V9.2002C1.4002 9.53157 1.66882 9.8002 2.0002 9.8002H3.2002C3.53157 9.8002 3.8002 9.53157 3.8002 9.2002V8.0002C3.8002 7.66882 4.06882 7.4002 4.4002 7.4002H5.6002C5.93157 7.4002 6.2002 7.66882 6.2002 8.0002V9.2002C6.2002 9.53157 6.46882 9.8002 6.8002 9.8002H8.0002C8.33157 9.8002 8.6002 9.53157 8.6002 9.2002V5.24872L8.77593 5.42446C9.01025 5.65877 9.39015 5.65877 9.62446 5.42446C9.85877 5.19015 9.85877 4.81025 9.62446 4.57593L5.42446 0.375931Z", fill: ROUTING_COLORS[routeIndex % ROUTING_COLORS.length].secondary }) }))) : (_jsx("div", __assign({ style: { fontWeight: 900 } }, { children: appointmentIndex }))),
                        glyphColor: ROUTING_COLORS[routeIndex % ROUTING_COLORS.length].secondary,
                        scale: 1,
                    }
                }); }),
                vehicle: {
                    position: ((_a = route.tracking_data) === null || _a === void 0 ? void 0 : _a.vehicle_location) ? {
                        lat: route.tracking_data.vehicle_location.lat,
                        lng: route.tracking_data.vehicle_location.lng,
                    } : null,
                    children: generateVehicle()
                },
                avatar: {
                    position: route.avatar_placement ? {
                        lat: route.avatar_placement.lat,
                        lng: route.avatar_placement.lng,
                    } : null,
                    children: generateAvatar(routeIndex, route.avatar),
                    icon: "data:image/png;base64,".concat(route.avatar)
                }
            });
        })) !== null && _b !== void 0 ? _b : [];
    }, [map, mapData]);
    var mapTypeId = useMemo(function () {
        if (mapType === MapType.SATELLITE) {
            return 'satellite';
        }
        if (mapType === MapType.TERRAIN) {
            return 'terrain';
        }
        if (mapType === MapType.TRAFFIC) {
            return 'roadmap';
        }
        return 'roadmap';
    }, [mapType]);
    if (googleMapAPILoadError) {
        handleLoadError(googleMapAPILoadError);
        return _jsx("div", { children: "Error loading Google Maps!" });
    }
    return (isGoogleMapAPILoaded ?
        _jsx("div", __assign({ className: 'grow relative' }, { children: routes.length > 0 ?
                _jsx(GoogleMap, __assign({ zoom: zoom, center: center, onLoad: onLoadMap, mapContainerStyle: containerStyle, mapTypeId: mapTypeId, options: {
                        mapId: process.env.REACT_APP_GOOGLE_MAPS_ID
                    } }, { children: routes.map(function (route, index) { return (_jsxs("div", { children: [_jsx(Polyline, { visible: routeLayers.includes('routes'), path: route.polyline.path, options: route.polyline.options }), _jsx(Polygon, { visible: routeLayers.includes('route-polygon'), onMouseDown: function () { return onClickPolygon(index); }, options: route.polygon.options, paths: route.polygon.area }), routeLayers.includes('homes') && route.homes.map(function (home, homeIndex) { return (_jsx(MapAdvancedMarker, __assign({}, home), homeIndex)); }), routeLayers.includes('service-vehicles') && route.vehicle.position && (_jsx(OverlayViewF, { zIndex: 1, position: route.vehicle.position, mapPaneName: 'floatPane', children: route.vehicle.children })), routeLayers.includes('service-pro') && route.avatar.position && (_jsx(OverlayViewF, __assign({ zIndex: 2, position: route.avatar.position, mapPaneName: "markerLayer" }, { children: route.avatar.children })))] }, index)); }) }))
                :
                    _jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'absolate text-center hidden' }, { children: "There is no Polygon data..." })), _jsx(GoogleMap, { zoom: zoom, center: center, mapContainerStyle: containerStyle })] }) }))
        : _jsx("div", { children: "Loading Google Maps..." }));
});
export default Map;
