var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { AptiveIcon, Checkbox } from '@aptive-env/storybook';
import { PLANNING_SHIFT_FULL_DAY_END_AT, PLANNING_SHIFT_FULL_DAY_START_AT } from 'interface';
import { format } from 'date-fns';
import { calculateTimeDifferenceInHours } from 'utils/calculateTimeDifferenceInHours';
export var PlanningCalendarDateShiftPopup = function (_a) {
    var date = _a.date, shift = _a.shift, onDeleteDateShift = _a.onDeleteDateShift, onUpdateDateShift = _a.onUpdateDateShift;
    var _b = useState(shift.start_at), startAt = _b[0], setStartAt = _b[1];
    var _c = useState(shift.end_at), endAt = _c[0], setEndAt = _c[1];
    var _d = useState(shift.shift_type), shiftType = _d[0], setShiftType = _d[1];
    var _e = useState(shift.start_at === PLANNING_SHIFT_FULL_DAY_START_AT && shift.end_at === PLANNING_SHIFT_FULL_DAY_END_AT), isFullDay = _e[0], setIsFullDay = _e[1];
    var _f = useState(false), hasError = _f[0], setHasError = _f[1];
    var workingDuration = useMemo(function () { return calculateTimeDifferenceInHours(startAt, endAt, 1); }, [startAt, endAt]);
    var totalHours = useMemo(function () {
        if (isFullDay) {
            return '8 hours';
        }
        return workingDuration > 0 ? "".concat(workingDuration, " hours") : '';
    }, [isFullDay, workingDuration]);
    useEffect(function () {
        if (startAt !== shift.start_at || endAt !== shift.end_at || shiftType !== shift.shift_type) {
            if (startAt >= endAt) {
                setHasError(true);
            }
            else {
                setHasError(false);
                onUpdateDateShift(date, __assign(__assign({}, shift), { start_at: startAt, end_at: endAt, shift_type: shiftType, working_duration: workingDuration }));
            }
        }
        else if (shiftType === 'disabled') {
            setShiftType('work');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, endAt, shiftType, startAt]);
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "flex justify-between items-center mb-4" }, { children: [_jsx("span", __assign({ className: "font-bold text-base text-gray-900" }, { children: format(date, 'd MMM yyyy') })), shift && (_jsx(AptiveIcon, { className: "w-6 stroke-[#9CA3AF] fill-none cursor-pointer", icon: "trash", onClick: function () { return onDeleteDateShift(); } }))] })), _jsxs("div", __assign({ className: "flex flex-col gap-3 mb-4" }, { children: [_jsx(Checkbox, __assign({ className: "rounded-sm border-gray-400", checked: isFullDay, onClick: function (e) {
                            e.stopPropagation();
                            e.preventDefault();
                            var newIsFullDay = !isFullDay;
                            setIsFullDay(newIsFullDay);
                            if (newIsFullDay) {
                                setStartAt(PLANNING_SHIFT_FULL_DAY_START_AT);
                                setEndAt(PLANNING_SHIFT_FULL_DAY_END_AT);
                            }
                        } }, { children: "Full day (8AM-5PM)" })), _jsx(Checkbox, __assign({ className: "rounded-sm border-gray-400", checked: shiftType === 'training', onClick: function (e) {
                            e.stopPropagation();
                            e.preventDefault();
                            setShiftType(shiftType !== 'training' ? 'training' : 'work');
                        } }, { children: "Training" }))] })), _jsxs("div", __assign({ className: "grid grid-cols-2 gap-4 mb-4" }, { children: [_jsxs("div", { children: [_jsx("label", __assign({ className: "mb-1 text-sm ".concat(isFullDay ? 'text-gray-400' : 'black') }, { children: "Start time:" })), _jsxs("div", __assign({ className: "flex items-center border rounded-md px-3 h-10 w-full ".concat(hasError ? 'border-red-600' : '') }, { children: [_jsx("input", { type: "time", className: "flex-1 outline-none text-sm read-only:text-gray-400", readOnly: isFullDay, value: startAt.slice(0, 5), onChange: function (e) { return setStartAt("".concat(e.target.value, ":00")); } }), isFullDay && (_jsx(AptiveIcon, { className: "w-4 stroke-[#9CA3AF] fill-none ml-[1px]", icon: "clock" }))] }))] }), _jsxs("div", { children: [_jsx("label", __assign({ className: "mb-1 text-sm ".concat(isFullDay ? 'text-gray-400' : 'black') }, { children: "End time:" })), _jsxs("div", __assign({ className: "flex items-center border rounded-md px-3 h-10 w-full ".concat(hasError ? 'border-red-600' : '') }, { children: [_jsx("input", { type: "time", className: "flex-1 outline-none text-sm read-only:text-gray-400", readOnly: isFullDay, value: endAt.slice(0, 5), onChange: function (e) { return setEndAt("".concat(e.target.value, ":00")); } }), isFullDay && (_jsx(AptiveIcon, { className: "w-5 stroke-[#9CA3AF] fill-none", icon: "clock" }))] }))] })] })), _jsxs("div", __assign({ className: "flex justify-between items-center" }, { children: [_jsx("span", __assign({ className: "text-sm" }, { children: "Total Hours:" })), _jsx("span", __assign({ className: "font-bold text-sm" }, { children: totalHours }))] }))] }));
};
