var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import aroAPI from './config';
import { format } from 'date-fns';
/**
 * Gets list of employees with their weekly working shifts.
 * @param officeId The ID of the office
 * @param startDate The start date for the weekly schedule - e.g. 2022/01/01
 * @returns Weekly schedules
 */
var fetchPlanningWeeklyShifts = function (_a, signal) {
    var officeId = _a.officeId, startDate = _a.startDate;
    return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, aroAPI.get('/planning/weekly-shifts', {
                        params: {
                            office_id: officeId,
                            start_date: startDate,
                        },
                        signal: signal,
                    })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response.data];
            }
        });
    });
};
var updatePlanningEmployeeShift = function (employeeId, _a, signal) {
    var date = _a.date, officeId = _a.officeId, startAt = _a.startAt, endAt = _a.endAt, shiftType = _a.shiftType;
    return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, aroAPI.patch("/planning/employees/".concat(employeeId, "/shifts"), {
                        date: format(date, 'yyyy-MM-dd'),
                        office_id: officeId,
                        start_at: startAt,
                        end_at: endAt,
                        shift_type: shiftType,
                    }, {
                        signal: signal,
                    })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response.data];
            }
        });
    });
};
var deletePlanningEmployeeShift = function (employeeId, _a, signal) {
    var date = _a.date, officeId = _a.officeId;
    return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, aroAPI.delete("/planning/employees/".concat(employeeId, "/shifts"), {
                        data: {
                            date: format(date, 'yyyy-MM-dd'),
                            office_id: officeId,
                        },
                        signal: signal
                    })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response.data];
            }
        });
    });
};
export { fetchPlanningWeeklyShifts, updatePlanningEmployeeShift, deletePlanningEmployeeShift };
