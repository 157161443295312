// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cH7tunXYat5BDahn6uyM > div {
  width: 100%;
}
.cH7tunXYat5BDahn6uyM > div > span {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgb(55, 65, 81, var(--tw-text-opacity));
}
.cH7tunXYat5BDahn6uyM > div > div > div {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgb(55, 65, 81, var(--tw-text-opacity));
}
.cH7tunXYat5BDahn6uyM > div > div > div div[aria-label=submenu] {
  bottom: 0px !important;
}
.cH7tunXYat5BDahn6uyM > div > div > nav button {
  height: 36px;
  width: 36px;
  padding: 0px;
}
.cH7tunXYat5BDahn6uyM > div * {
  font-weight: 400 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/TableFooter/index.module.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;AAEI;EACE,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,oBAAA;EACA,8CAAA;AAAN;AAIM;EACE,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,oBAAA;EACA,8CAAA;AAFR;AAIQ;EACE,sBAAA;AAFV;AAOQ;EACE,YAAA;EACA,WAAA;EACA,YAAA;AALV;AAUI;EACE,2BAAA;AARN","sourcesContent":[".paginationWrapper {\n  & > div {\n    width: 100%;\n\n    & > span {\n      font-size: 0.875rem;\n      line-height: 1.25rem;\n      font-weight: 400;\n      --tw-text-opacity: 1;\n      color: rgb(55 65 81 / var(--tw-text-opacity));\n    }\n\n    & > div {\n      & > div {\n        font-size: 0.875rem;\n        line-height: 1.25rem;\n        font-weight: 400;\n        --tw-text-opacity: 1;\n        color: rgb(55 65 81 / var(--tw-text-opacity));\n\n        div[aria-label=\"submenu\"] {\n          bottom: 0px !important;\n        }\n      }\n\n      & > nav {\n        button {\n          height: 36px;\n          width: 36px;\n          padding: 0px;\n        }\n      }\n    }\n\n    * {\n      font-weight: 400 !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationWrapper": `cH7tunXYat5BDahn6uyM`
};
export default ___CSS_LOADER_EXPORT___;
