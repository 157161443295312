// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@tailwind base;
@tailwind components;
@tailwind utilities;
html, body, #root {
  height: 100%;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #FFFFFF;
}

*::-webkit-scrollbar {
  width: 8px;
  background-color: #FFFFFF;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #9CA3AF;
}

.gmnoprint {
  display: none;
}

.gm-fullscreen-control {
  display: none;
}`, "",{"version":3,"sources":["webpack://./node_modules/tailwindcss/base.css","webpack://./node_modules/tailwindcss/components.css","webpack://./node_modules/tailwindcss/utilities.css","webpack://./src/pages/MapView/index.scss"],"names":[],"mappings":"AAAA,cAAA;ACAA,oBAAA;ACAA,mBAAA;ACIA;EACE,YAAA;AAAF;;AAGA;EAEC,mBAAA;EACA,yBAAA;AADD;;AAIA;EAEC,UAAA;EACA,yBAAA;AAFD;;AAKA;EAEC,mBAAA;EACA,yBAAA;AAHD;;AAMA;EACE,aAAA;AAHF;;AAMA;EACE,aAAA;AAHF","sourcesContent":["@tailwind base;\n","@tailwind components;\n","@tailwind utilities;\n","@import 'tailwindcss/base';\n@import 'tailwindcss/components';\n@import 'tailwindcss/utilities';\n\nhtml, body, #root {\n  height: 100%;\n}\n\n*::-webkit-scrollbar-track\n{\n\tborder-radius: 10px;\n\tbackground-color: #FFFFFF;\n}\n\n*::-webkit-scrollbar\n{\n\twidth: 8px;\n\tbackground-color: #FFFFFF;\n}\n\n*::-webkit-scrollbar-thumb\n{\n\tborder-radius: 10px;\n\tbackground-color: #9CA3AF;\n}\n\n.gmnoprint {\n  display: none;\n}\n\n.gm-fullscreen-control {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
