var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { AppContext } from 'pages/CalendarView';
import Autocomplete from 'react-google-autocomplete';
import { AptiveIcon } from '@aptive-env/storybook';
export var EventLocationInput = function (_a) {
    var _b;
    var initAddress = _a.initAddress, setEventData = _a.setEventData, setErrors = _a.setErrors;
    var isGoogleMapAPILoaded = useContext(AppContext).isGoogleMapAPILoaded;
    return (_jsxs("div", { children: [_jsx("p", __assign({ className: "font-inter text-sm font-normal leading-4 text-dark-gray-100" }, { children: "Location" })), _jsxs("div", __assign({ className: "flex mt-1 border h-[38px] px-[13px] py-[9px] rounded-lg" }, { children: [_jsx(AptiveIcon, { icon: "locationMarker", className: "w-5 h-5 stroke-[#9CA3AF] fill-none cursor-pointer" }), _jsx("div", __assign({ className: "ml-3 flex flex-col grow gap-5" }, { children: isGoogleMapAPILoaded && (_jsx(Autocomplete, { placeholder: "Enter location", className: "w-full outline-none text-sm leading-4 placeholder-[#A9A9A9] text-[#111827] border-transparent focus:border-transparent focus:ring-0 p-0", apiKey: (_b = process.env.REACT_APP_GOOGLE_MAPS_API_KEY) !== null && _b !== void 0 ? _b : '', options: {
                                types: [],
                                componentRestrictions: { country: 'us' },
                            }, defaultValue: initAddress, onPlaceSelected: function (place) {
                                var _a, _b;
                                var tempLocationData = {
                                    address: place.formatted_address || '',
                                    city: '',
                                    state: '',
                                    zip: '',
                                };
                                (_a = place.address_components) === null || _a === void 0 ? void 0 : _a.forEach(function (address_component) {
                                    if (address_component.types.includes('locality'))
                                        tempLocationData.city = address_component.short_name;
                                    if (address_component.types.includes('administrative_area_level_1'))
                                        tempLocationData.state = address_component.short_name;
                                    if (address_component.types.includes('postal_code'))
                                        tempLocationData.zip = address_component.short_name;
                                });
                                setEventData(function (prevData) { return (__assign(__assign({}, prevData), { locationData: tempLocationData })); });
                                if ((_b = place.geometry) === null || _b === void 0 ? void 0 : _b.location) {
                                    var placeLat_1 = place.geometry.location.lat();
                                    var placeLng_1 = place.geometry.location.lng();
                                    setEventData(function (prevData) { return (__assign(__assign({}, prevData), { geoLocation: { lat: placeLat_1, lng: placeLng_1 } })); });
                                }
                                var missingLocationData = [];
                                Object.keys(tempLocationData).forEach(function (key) {
                                    if (tempLocationData[key] === '')
                                        missingLocationData.push(key);
                                });
                                if (missingLocationData.length) {
                                    var errorMsg_1 = 'Your location doesn\'t contain ' +
                                        missingLocationData.join(', ') +
                                        '.';
                                    setErrors(function (prevErrors) { return (__assign(__assign({}, prevErrors), { address: errorMsg_1 })); });
                                }
                            } })) }))] }))] }));
};
