import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const TableRow = ({ row, isStickyHeader }) => {
  return (
    <tr className="text-xs font-semibold text-gray-600">
      {row.map((value) => {
        const key = uuidv4();
        const tdClass = classNames(
          'px-3 py-6 whitespace-nowrap bt-1 border-gray-200',
          { 'text-left': isNaN(value) },
          { 'text-right': !isNaN(value) },
          { 'border-b': isStickyHeader },
        );

        return (
          <td
            key={key}
            scope="col"
            className={tdClass}
          >
            {value}
          </td>
        );
      })}
    </tr>
  );
};

TableRow.propTypes = {
  row: PropTypes.array,
  isStickyHeader: PropTypes.bool,
};

TableRow.defaultProps = {
  isStickyHeader: false,
};

export default TableRow;
