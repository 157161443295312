import { fileValidationSchema } from '@/components/common/Form/CustomFile/fileValidationSchema';
import { onboardingConstants, onboardingDataValues } from '@/lib/constants';
import {
  ACCOUNT_NUMBER_SCHEMA_REGEX,
  REQUIRED,
  ROUTING_NUMBER_REGEX,
  WOTC_SURVEY_MESSAGE,
  W9_MESSAGE,
  I9_MESSAGE,
} from '@/lib/validations';
import * as yup from 'yup';

const {
  PASSPORT_VALUE,
  DL_AND_SOCIAL_VALUE,
} = onboardingDataValues;

const {
  I9_DOCUMENTS_SECTION_FEATURE_FLAG_NAME,
  DIRECT_DEPOSIT_SECTION_FEATURE_FLAG_NAME,

  // W-9 documents Section
  W9_CLICKED,
  USE_PREVIOUS_W9_NAME,

  // I-9 documents Section
  I9_CLICKED,

  // WOTC Tax Survey Section
  WOTC_SURVEY_COMPLETED,

  // ID Copy Upload Section
  USES_TYPE,
  PASSPORT_PICTURE,
  PASSPORT_PICTURE_LOCAL_FILE,
  PASSPORT_EXPIRATION_DATE,
  DRIVER_LICENSE,
  DRIVER_LICENSE_LOCAL_FILE,
  SOCIAL_SECURITY_CARD,
  SOCIAL_SECURITY_PICTURE_LOCAL_FILE,

  // Direct Deposit Section
  DIRECT_DEPOSIT_BANK_NAME,
  DIRECT_DEPOSIT_ACCOUNT_NAME,
  DIRECT_DEPOSIT_ACCOUNT_TYPE,
  DIRECT_DEPOSIT_ROUTING_NUMBER,
  DIRECT_DEPOSIT_ACCOUNT_NUMBER,
  DIRECT_DEPOSIT_CONFIRM_ACCOUNT_NUMBER,
} = onboardingConstants;

const passportUseTypeSchema = yup
  .string()
  .when(
    USES_TYPE,
    (usesType) => {
      if (usesType === PASSPORT_VALUE) {
        return yup
          .string()
          .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
            return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
          });
      } else {
        return yup.string().nullable().notRequired();
      }
    },
  );

const driverLicenseAndSocialUseTypeSchema = yup
  .string()
  .when(
    USES_TYPE,
    (usesType) => {
      if (usesType === DL_AND_SOCIAL_VALUE) {
        return yup
          .string()
          .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
            return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
          });
      } else {
        return yup.string().nullable().notRequired();
      }
    },
  );

export const composeValidationSchema = (featureFlags) => {
  const {
    [I9_DOCUMENTS_SECTION_FEATURE_FLAG_NAME]: isI9DocumentsSectionEnabled,
    [DIRECT_DEPOSIT_SECTION_FEATURE_FLAG_NAME]: isDirectDepositSectionEnabled,
  } = featureFlags;

  return yup.object().shape({
    // W-9 documents Section
    [W9_CLICKED]: yup
      .bool()
      .when([USE_PREVIOUS_W9_NAME], {
        is: false,
        then: yup.bool()
          .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
            return !isWizard && isAdmin ? schema : schema.oneOf([true], W9_MESSAGE);
          }),
        otherwise: yup.bool().notRequired(),
      }),

    // I-9 documents Section
    ...(isI9DocumentsSectionEnabled && {
      [I9_CLICKED]: yup
        .bool()
        .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
          return (!isWizard && isAdmin) ? schema : schema.oneOf([true], I9_MESSAGE);
        }),
    }),

    // WOTC Tax Survey Section
    [WOTC_SURVEY_COMPLETED]: yup
      .bool()
      .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
        return !isWizard && isAdmin ? schema : schema.oneOf([true], WOTC_SURVEY_MESSAGE);
      }),

    // ID Copy Upload Section
    [USES_TYPE]: yup
      .string()
      .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
        return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
      })
      .nullable(),
    [PASSPORT_PICTURE]: passportUseTypeSchema,
    [PASSPORT_PICTURE_LOCAL_FILE]: fileValidationSchema,
    [PASSPORT_EXPIRATION_DATE]: passportUseTypeSchema,
    [DRIVER_LICENSE]: driverLicenseAndSocialUseTypeSchema,
    [DRIVER_LICENSE_LOCAL_FILE]: fileValidationSchema,
    [SOCIAL_SECURITY_CARD]: driverLicenseAndSocialUseTypeSchema,
    [SOCIAL_SECURITY_PICTURE_LOCAL_FILE]: fileValidationSchema,

    // Direct Deposit Section
    ...(isDirectDepositSectionEnabled && {
      [DIRECT_DEPOSIT_BANK_NAME]: yup
        .string()
        .checkWhiteSpacesOnly(REQUIRED)
        .when([
          '$defaultValues',
          '$isAdmin',
          '$isWizard',
          DIRECT_DEPOSIT_ACCOUNT_TYPE,
          DIRECT_DEPOSIT_ROUTING_NUMBER,
          DIRECT_DEPOSIT_ACCOUNT_NAME,
          DIRECT_DEPOSIT_ACCOUNT_NUMBER,
        ], (
          defaultValues,
          isAdmin,
          isWizard,
          accountType,
          routingNumber,
          accountName,
          accountNumber,
          schema,
        ) => {
          const directDepositChanged =
            defaultValues[DIRECT_DEPOSIT_ACCOUNT_TYPE] !== accountType ||
            defaultValues[DIRECT_DEPOSIT_ROUTING_NUMBER] !== routingNumber ||
            defaultValues[DIRECT_DEPOSIT_ACCOUNT_NAME] !== accountName ||
            defaultValues[DIRECT_DEPOSIT_ACCOUNT_NUMBER] !== accountNumber;

          if (directDepositChanged || isWizard || !isAdmin) {
            return schema.required(REQUIRED);
          }

          return schema;
        })
        .max(50, 'Bank name is too long.'),
      [DIRECT_DEPOSIT_ACCOUNT_NAME]: yup
        .string()
        .checkWhiteSpacesOnly(REQUIRED)
        .when([
          '$defaultValues',
          '$isAdmin',
          '$isWizard',
          DIRECT_DEPOSIT_ACCOUNT_TYPE,
          DIRECT_DEPOSIT_ROUTING_NUMBER,
          DIRECT_DEPOSIT_BANK_NAME,
          DIRECT_DEPOSIT_ACCOUNT_NUMBER,
        ], (
          defaultValues,
          isAdmin,
          isWizard,
          accountType,
          routingNumber,
          bankName,
          accountNumber,
          schema,
        ) => {
          const directDepositChanged =
            defaultValues[DIRECT_DEPOSIT_ACCOUNT_TYPE] !== accountType ||
            defaultValues[DIRECT_DEPOSIT_ROUTING_NUMBER] !== routingNumber ||
            defaultValues[DIRECT_DEPOSIT_BANK_NAME] !== bankName ||
            defaultValues[DIRECT_DEPOSIT_ACCOUNT_NUMBER] !== accountNumber;

          if (directDepositChanged || isWizard || !isAdmin) {
            return schema.required(REQUIRED);
          }

          return schema;
        })
        .max(50, 'Account name is too long.'),
      [DIRECT_DEPOSIT_ACCOUNT_TYPE]: yup
        .string()
        .when([
          '$defaultValues',
          '$isAdmin',
          '$isWizard',
          DIRECT_DEPOSIT_ACCOUNT_NAME,
          DIRECT_DEPOSIT_ROUTING_NUMBER,
          DIRECT_DEPOSIT_BANK_NAME,
          DIRECT_DEPOSIT_ACCOUNT_NUMBER,
        ], (
          defaultValues,
          isAdmin,
          isWizard,
          accountName,
          routingNumber,
          bankName,
          accountNumber,
          schema,
        ) => {
          const directDepositChanged =
            defaultValues[DIRECT_DEPOSIT_ACCOUNT_NAME] !== accountName ||
            defaultValues[DIRECT_DEPOSIT_ROUTING_NUMBER] !== routingNumber ||
            defaultValues[DIRECT_DEPOSIT_BANK_NAME] !== bankName ||
            defaultValues[DIRECT_DEPOSIT_ACCOUNT_NUMBER] !== accountNumber;

          if (directDepositChanged || isWizard || !isAdmin) {
            return schema.oneOf(
              ['Checking', 'Savings'],
              'Account type must be either Checking or Savings.',
            );
          }

          return schema;
        }),
      [DIRECT_DEPOSIT_ROUTING_NUMBER]: yup
        .string()
        .when([
          '$defaultValues',
          '$isAdmin',
          '$isWizard',
          DIRECT_DEPOSIT_ACCOUNT_NAME,
          DIRECT_DEPOSIT_ACCOUNT_TYPE,
          DIRECT_DEPOSIT_BANK_NAME,
          DIRECT_DEPOSIT_ACCOUNT_NUMBER,
        ], (
          defaultValues,
          isAdmin,
          isWizard,
          accountName,
          accountType,
          bankName,
          accountNumber,
          schema,
        ) => {
          const directDepositChanged =
            defaultValues[DIRECT_DEPOSIT_ACCOUNT_NAME] !== accountName ||
            defaultValues[DIRECT_DEPOSIT_ACCOUNT_TYPE] !== accountType ||
            defaultValues[DIRECT_DEPOSIT_BANK_NAME] !== bankName ||
            defaultValues[DIRECT_DEPOSIT_ACCOUNT_NUMBER] !== accountNumber;

          if (directDepositChanged || isWizard || !isAdmin) {
            return schema.required(REQUIRED);
          }

          return schema;
        })
        .matches(
          ROUTING_NUMBER_REGEX,
          {
            excludeEmptyString: true,
            message: 'Routing number must be exactly 9 digits.',
          },
        ),
      [DIRECT_DEPOSIT_ACCOUNT_NUMBER]: yup
        .string()
        .when(
          [
            '$defaultValues',
            DIRECT_DEPOSIT_ACCOUNT_NAME,
            DIRECT_DEPOSIT_ACCOUNT_TYPE,
            DIRECT_DEPOSIT_BANK_NAME,
            DIRECT_DEPOSIT_ROUTING_NUMBER,
          ],
          (
            defaultValues,
            accountName,
            accountType,
            bankName,
            routingNumber,
            schema,
          ) => {
            const directDepositChanged =
              defaultValues[DIRECT_DEPOSIT_ACCOUNT_NAME] !== accountName ||
              defaultValues[DIRECT_DEPOSIT_ACCOUNT_TYPE] !== accountType ||
              defaultValues[DIRECT_DEPOSIT_BANK_NAME] !== bankName ||
              defaultValues[DIRECT_DEPOSIT_ROUTING_NUMBER] !== routingNumber;

            return schema.matches(ACCOUNT_NUMBER_SCHEMA_REGEX, {
              // Validate account number against the regex always.
              // But require it only if Direct Deposit fields have changed.
              excludeEmptyString: !directDepositChanged,
              message: 'Account number must be between 1 to 17 digits.',
            });
          },
        ),
      [DIRECT_DEPOSIT_CONFIRM_ACCOUNT_NUMBER]: yup
        .string()
        .when([DIRECT_DEPOSIT_ACCOUNT_NUMBER], (accountNumber, schema) => {
          return schema.oneOf([accountNumber], 'Account numbers must match.');
        }),
    }),
  }, [
    [DIRECT_DEPOSIT_BANK_NAME, DIRECT_DEPOSIT_ACCOUNT_TYPE],
    [DIRECT_DEPOSIT_BANK_NAME, DIRECT_DEPOSIT_ROUTING_NUMBER],
    [DIRECT_DEPOSIT_BANK_NAME, DIRECT_DEPOSIT_ACCOUNT_NAME],
    [DIRECT_DEPOSIT_BANK_NAME, DIRECT_DEPOSIT_ACCOUNT_NUMBER],
    [DIRECT_DEPOSIT_ACCOUNT_NAME, DIRECT_DEPOSIT_ACCOUNT_TYPE],
    [DIRECT_DEPOSIT_ACCOUNT_NAME, DIRECT_DEPOSIT_ROUTING_NUMBER],
    [DIRECT_DEPOSIT_ACCOUNT_NAME, DIRECT_DEPOSIT_ACCOUNT_NUMBER],
    [DIRECT_DEPOSIT_ACCOUNT_TYPE, DIRECT_DEPOSIT_ACCOUNT_NUMBER],
    [DIRECT_DEPOSIT_ACCOUNT_TYPE, DIRECT_DEPOSIT_ROUTING_NUMBER],
    [DIRECT_DEPOSIT_ROUTING_NUMBER, DIRECT_DEPOSIT_ACCOUNT_NUMBER],
  ]);
};
