var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { formatInTimeZone } from 'date-fns-tz';
import { format } from 'date-fns';
import Popover from '../shared/Popover';
import { AptiveIcon } from '@aptive-env/storybook';
import { fetchCalendarEvents, fetchCalendarEventParticipants } from 'services/calendar';
import { convert24HourTo12Hour } from 'utils/convertHour';
import { Colors } from 'constants/colors';
import EventDetailModal from 'components/EventDetailModal';
var CalendarSearchInput = function (_a) {
    var onSearch = _a.onSearch;
    var _b = useState(''), searchValue = _b[0], setSearchValue = _b[1];
    var onInputChange = function (e) {
        setSearchValue(e.target.value);
        onSearch(e.target.value);
    };
    return (_jsxs("div", __assign({ className: "flex items-center bg-white border border-[#E5E7EB] rounded-md h-[38px] min-w-[320px] overflow-hidden" }, { children: [_jsx("input", { className: "pl-[12px] w-full focus-visible:outline-none text-[#3d3d3d] text-sm placeholder-[#A9A9A9] border-none focus:border-none focus:ring-0", value: searchValue, onChange: onInputChange, placeholder: "Search for events" }), _jsx("div", __assign({ className: "p-[10px]" }, { children: _jsx(AptiveIcon, { className: "w-[20px] h-[20px] stroke-aro-gray fill-none", icon: "search" }) }))] })));
};
var ResultedEvent = function (_a) {
    var event = _a.event, onEventSuccess = _a.onEventSuccess;
    var _b = useState(false), isEventDetailOpened = _b[0], setIsEventDetailOpened = _b[1];
    var _c = useState(false), isEventDetailLoading = _c[0], setIsEventDetailLoading = _c[1];
    var _d = useState(null), selectedEvent = _d[0], setSelectedEvent = _d[1];
    var _e = useState([]), selectedEventParticipants = _e[0], setSelectedEventParticipants = _e[1];
    var _f = useState({}), mapOfGroupedOverlappingEvents = _f[0], setMapOfGroupedOverlappingEvents = _f[1];
    var date = new Date(event.date);
    var handleSelect = function (event) {
        setSelectedEvent(event);
        setIsEventDetailOpened(true);
        setIsEventDetailLoading(true);
        setSelectedEventParticipants([]);
        fetchCalendarEventParticipants(event.id)
            .then(function (response) {
            setSelectedEventParticipants(response);
            setIsEventDetailLoading(false);
        });
    };
    var handleHideShowEvent = function (eventId, eventDate, isAll, isHidden) {
        var tempMapOfGroupedOverlappingEvents = __assign({}, mapOfGroupedOverlappingEvents);
        for (var _i = 0, _a = Object.keys(tempMapOfGroupedOverlappingEvents); _i < _a.length; _i++) {
            var date_1 = _a[_i];
            var tempGroupedOverlappingEvents = tempMapOfGroupedOverlappingEvents[date_1];
            for (var _b = 0, tempGroupedOverlappingEvents_1 = tempGroupedOverlappingEvents; _b < tempGroupedOverlappingEvents_1.length; _b++) {
                var tempGroupedOverlappingEvent = tempGroupedOverlappingEvents_1[_b];
                for (var _c = 0, tempGroupedOverlappingEvent_1 = tempGroupedOverlappingEvent; _c < tempGroupedOverlappingEvent_1.length; _c++) {
                    var event_1 = tempGroupedOverlappingEvent_1[_c];
                    if (isAll && event_1.id === eventId) {
                        event_1.is_hidden = isHidden;
                        break;
                    }
                    if (!isAll && event_1.id === eventId && format(eventDate, 'yyyy-MM-dd') === date_1) {
                        event_1.is_hidden = isHidden;
                        break;
                    }
                }
            }
        }
        setMapOfGroupedOverlappingEvents(tempMapOfGroupedOverlappingEvents);
    };
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "flex p-2 gap-2 cursor-pointer hover:bg-[#F0F0F0]", onClick: function () { return handleSelect(event); } }, { children: [_jsx("span", { className: "mt-[2px] w-[12px] h-[12px] rounded-[50%] bg-aro-blue" }), _jsxs("div", __assign({ className: "flex flex-col grow" }, { children: [_jsxs("div", __assign({ className: "flex items-center gap-1" }, { children: [_jsx("span", __assign({ className: "text-[11px] font-inter font-semibold text-aro-gray leading-4" }, { children: "".concat(convert24HourTo12Hour(event.start_at), " - ").concat(convert24HourTo12Hour(event.end_at)) })), _jsx("span", __assign({ className: "flex items-center justify-center w-[12px] h-[12px] rounded-[50%] bg-[#0EA5E926]" }, { children: _jsx(AptiveIcon, { className: 'w-[8px] h-[8px] stroke-none', icon: "videoCamera", style: { fill: Colors.ARO_BLUE } }) }))] })), _jsx("span", __assign({ className: "text-xs font-inter font-medium text-[#3d3d3d]" }, { children: event.title })), _jsxs("span", __assign({ className: "text-[10px] font-inter font-medium text-aro-gray leading-4 capitalize" }, { children: [formatInTimeZone(date, 'UTC', 'LLLL, d yyyy'), " - (", event.interval, ")"] }))] }))] })), selectedEvent && (_jsx(EventDetailModal, { event: selectedEvent, eventDate: date, participants: selectedEventParticipants, loading: isEventDetailLoading, open: isEventDetailOpened, onDelete: function () { }, onHideShow: handleHideShowEvent, onSuccess: onEventSuccess, onClose: function () { return setIsEventDetailOpened(false); } }))] }));
};
var CalendarSearchPopoverContent = function (_a) {
    var items = _a.items, onEventSuccess = _a.onEventSuccess;
    return (_jsxs("div", __assign({ className: "flex flex-col w-[288px] p-[10px] max-h-[340px] overflow-auto" }, { children: [items.map(function (item, index) {
                return _jsx(ResultedEvent, { event: item, onEventSuccess: onEventSuccess }, index);
            }), items.length === 0 && (_jsx("p", __assign({ className: "text-xs text-center font-inter font-medium text-[#3d3d3d]" }, { children: "No events found" })))] })));
};
var CalendarSearch = function (_a) {
    var officeId = _a.officeId, onEventSuccess = _a.onEventSuccess;
    var _b = useState(false), isCalendarSearchPopoverOpen = _b[0], setCalendarSearchPopoverOpen = _b[1];
    var _c = useState([]), searchResult = _c[0], setSearchResult = _c[1];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var debouncedSearch = useCallback(debounce(function (query) { return __awaiter(void 0, void 0, void 0, function () {
        var eventsResult, events_1, tempResults_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setCalendarSearchPopoverOpen(false);
                    if (!(query && query.length > 1)) return [3 /*break*/, 2];
                    return [4 /*yield*/, fetchCalendarEvents({
                            office_id: officeId,
                            search_text: query
                        })];
                case 1:
                    eventsResult = _a.sent();
                    events_1 = eventsResult.result.events;
                    tempResults_1 = [];
                    Object.keys(events_1).forEach(function (date) {
                        events_1[date].forEach(function (event) {
                            tempResults_1.push(__assign(__assign({}, event), { date: date }));
                        });
                    });
                    setSearchResult(tempResults_1);
                    setCalendarSearchPopoverOpen(true);
                    return [3 /*break*/, 3];
                case 2:
                    setSearchResult([]);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, 1000), [officeId]);
    return (_jsx(Popover, __assign({ open: isCalendarSearchPopoverOpen, position: "bottom", content: _jsx(CalendarSearchPopoverContent, { items: searchResult, onEventSuccess: onEventSuccess }), className: "right-0 rounded-[10px]", onClickOutside: function () { return setCalendarSearchPopoverOpen(false); } }, { children: _jsx(CalendarSearchInput, { onSearch: debouncedSearch }) })));
};
export default CalendarSearch;
