var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';
import Popover from 'components/shared/Popover';
import MiniCalendar from 'components/shared/MiniCalendar';
import TimePicker from 'components/shared/TimePicker';
import { eventConstants } from 'constants/index';
var timeOptions = eventConstants.timeOptions;
export var EventTimePicker = function (_a) {
    var eventData = _a.eventData, setEventData = _a.setEventData, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var _c = useState({
        isStartTimePickerOpen: false,
        isEndTimePickerOpen: false,
        isDatePickerOpen: false,
    }), isTimePickerOpen = _c[0], setIsTimePickerOpen = _c[1];
    var handleChangeSelectedDate = function (newDate) {
        setEventData(function (prevData) { return (__assign(__assign({}, prevData), { selectedDate: newDate })); });
        toggleDatePickerShown();
    };
    var toggleDatePickerShown = function () {
        setIsTimePickerOpen(function (prevValue) { return (__assign(__assign({}, prevValue), { isDatePickerOpen: !prevValue.isDatePickerOpen })); });
    };
    var toggleStartTimePickerShown = function () {
        setIsTimePickerOpen(function (prevValue) { return (__assign(__assign({}, prevValue), { isStartTimePickerOpen: !prevValue.isStartTimePickerOpen })); });
    };
    var toggleEndTimePickerShown = function () {
        setIsTimePickerOpen(function (prevValue) { return (__assign(__assign({}, prevValue), { isEndTimePickerOpen: !prevValue.isEndTimePickerOpen })); });
    };
    var handleChangeSelectedStartTime = function (time) {
        setEventData(function (prevData) { return (__assign(__assign({}, prevData), { selectedStartTime: time })); });
        setIsTimePickerOpen(function (prevValue) { return (__assign(__assign({}, prevValue), { isStartTimePickerOpen: false })); });
        var startTimeIndex = timeOptions.indexOf(time);
        var endTimeIndex = timeOptions.indexOf(eventData.selectedEndTime);
        if (startTimeIndex >= endTimeIndex)
            setEventData(function (prevData) { return (__assign(__assign({}, prevData), { selectedEndTime: timeOptions[startTimeIndex + 1] })); });
    };
    var handleChangeSelectedEndTime = function (time) {
        setEventData(function (prevData) { return (__assign(__assign({}, prevData), { selectedEndTime: time })); });
        setIsTimePickerOpen(function (prevValue) { return (__assign(__assign({}, prevValue), { isEndTimePickerOpen: false })); });
        var startTimeIndex = timeOptions.indexOf(eventData.selectedStartTime);
        var endTimeIndex = timeOptions.indexOf(time);
        if (startTimeIndex >= endTimeIndex)
            setEventData(function (prevData) { return (__assign(__assign({}, prevData), { selectedStartTime: timeOptions[endTimeIndex - 1] })); });
    };
    return (_jsxs("div", { children: [_jsx("p", __assign({ className: "font-inter text-sm font-normal leading-4 text-dark-gray-100" }, { children: "When" })), _jsx("div", __assign({ className: "mt-1" }, { children: _jsxs("div", __assign({ className: "flex justify-between gap-2 [&>div:first-child]:grow" }, { children: [_jsx(Popover, __assign({ open: isTimePickerOpen.isDatePickerOpen, content: _jsx("div", __assign({ className: "absolute bg-white p-[10px] rounded-lg", style: {
                                    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
                                } }, { children: _jsx(MiniCalendar, { view: "month", currentDate: eventData.selectedDate, onChange: handleChangeSelectedDate }) })), position: "bottom", onClickOutside: toggleDatePickerShown }, { children: _jsx("div", __assign({ className: classNames('relative h-[38px] border rounded-lg py-[6px] px-[12px] font-inter text-sm font-normal leading-4 flex items-center', disabled ? 'text-gray-400' : 'text-dark-gray-100  cursor-pointer'), onClick: disabled ? function () { } : toggleDatePickerShown }, { children: _jsx("span", { children: format(eventData.selectedDate, 'EEEE, MMM d') }) })) })), _jsx(Popover, __assign({ open: isTimePickerOpen.isStartTimePickerOpen, content: _jsx("div", __assign({ className: "absolute bg-white rounded-lg h-[180px] w-[90px] overflow-auto", style: {
                                    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
                                } }, { children: _jsx(TimePicker, { value: eventData.selectedStartTime, onChange: handleChangeSelectedStartTime, options: timeOptions.slice(0, -1) }) })), position: "bottom", onClickOutside: toggleStartTimePickerShown }, { children: _jsx("div", __assign({ className: classNames('h-[38px] w-[90px] border rounded-lg py-[6px] px-2 font-inter text-sm font-normal leading-4 flex items-center', disabled ? 'text-gray-400' : 'text-dark-gray-100  cursor-pointer'), onClick: disabled ? function () { } : toggleStartTimePickerShown }, { children: eventData.selectedStartTime })) })), _jsx(Popover, __assign({ open: isTimePickerOpen.isEndTimePickerOpen, content: _jsx("div", __assign({ className: "absolute bg-white rounded-lg h-[180px] w-[90px] overflow-auto", style: {
                                    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
                                } }, { children: _jsx(TimePicker, { value: eventData.selectedEndTime, onChange: handleChangeSelectedEndTime, options: timeOptions.slice(1) }) })), position: "bottom", onClickOutside: toggleEndTimePickerShown }, { children: _jsx("div", __assign({ className: classNames('h-[38px] w-[90px] border rounded-lg py-[6px] px-2 font-inter text-sm font-normal leading-4 flex items-center', disabled ? 'text-gray-400' : 'text-dark-gray-100 cursor-pointer'), onClick: disabled ? function () { } : toggleEndTimePickerShown }, { children: eventData.selectedEndTime })) }))] })) }))] }));
};
