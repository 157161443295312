import * as Api from '@/api/api';

const api = process.env.REACT_APP_PLAN_BUILDER_API;

const path = '/api/v1/histories';

const filterEditHistories = Api.post({ path, api });

const getHistoryOptions = Api.get({ path: `${path}/options`, api });

const endpoints = {
  filterEditHistories,
  getHistoryOptions,
};

export default endpoints;
