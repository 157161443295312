import { set } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
var calculateTimeLeft = function (targetDate, timezone) {
    var currentDate = timezone ? toZonedTime(new Date(), timezone) : new Date();
    var targetDateAt8AM = set(targetDate, { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 });
    var difference = +targetDateAt8AM - +currentDate;
    var timeLeft = '';
    if (difference > 0) {
        var days = Math.floor(difference / (1000 * 60 * 60 * 24));
        var hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        var minutes = Math.floor((difference / 1000 / 60) % 60);
        timeLeft = "".concat(days, " days ").concat(hours, " hrs ").concat(minutes, " mins");
    }
    else {
        timeLeft = '';
    }
    return timeLeft;
};
export { calculateTimeLeft };
