import { trim } from 'lodash-es';

export const validateFileUpload = (fileType, file) => {
  switch (fileType) {
    case 'image':
      if (!file.size || file.size > 5242880) {
        return 'size';
      }
      if (!file.type || !file.type.startsWith('image/')) {
        return 'invalidType';
      }
      return true;
    default:
      return true;
  }
};

export const validateStringLength = (value, limit, limitType) => {
  let isValid = true;

  if (limitType === 'min') {
    if (value.length && trim(value).length < limit) {
      isValid = false;
    }
  } else if (limitType === 'max') {
    if (value.length && trim(value).length > limit) {
      isValid = false;
    }
  }

  return isValid;
};
