var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { AptiveIcon } from '@aptive-env/storybook';
import { addDays, format } from 'date-fns';
import AsyncRenderOnScroll from 'components/shared/AsyncRenderOnScroll';
import LoadingGif from 'assets/image/loading.gif';
import { fetchUserAvatar } from 'services/calendar';
import { PlanningCalendarShiftItem } from './PlanningCalendarShiftItem';
export var PlanningCalendarRow = function (_a) {
    var office = _a.office, startDate = _a.startDate, employee = _a.employee, holidays = _a.holidays, isProcessing = _a.isProcessing, currentIndex = _a.currentIndex, lastIndex = _a.lastIndex, setIsProcessing = _a.setIsProcessing, onToggleDateShift = _a.onToggleDateShift, onDeleteDateShift = _a.onDeleteDateShift, onUpdateDateShift = _a.onUpdateDateShift;
    var dateRange = useMemo(function () {
        var daysToLoop = 6;
        return Array.from({ length: daysToLoop + 1 }, function (_, i) { return addDays(startDate, i); });
    }, [startDate]);
    /**
     * Returns holiday if the date is a holiday.
     * @param date yyyy-MM-dd e.g. 2024-09-22
     */
    var getHoliday = useCallback(function (date) {
        return holidays.find(function (holiday) { return holiday.date === date; });
    }, [holidays]);
    var renderEmployeeDefaultAvatar = function (employee) {
        return (_jsx("div", __assign({ className: "w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center" }, { children: employee.name[0] })));
    };
    var renderEmployeeLoadingAvatar = function (employee) {
        return (_jsx("div", __assign({ className: "w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center" }, { children: _jsx("img", { src: LoadingGif, alt: employee.name[0], className: "w-[10px]" }) })));
    };
    var renderEmployeeAvatar = function (employee) {
        if (employee.external_id) {
            return (_jsx(AsyncRenderOnScroll, { loadingComponent: renderEmployeeLoadingAvatar(employee), fetchData: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var response;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, fetchUserAvatar(employee.external_id, true)];
                            case 1:
                                response = _a.sent();
                                return [2 /*return*/, response.result.avatarBase64
                                        ? "data:image/png;base64,".concat(response.result.avatarBase64)
                                        : null];
                        }
                    });
                }); }, render: function (data) {
                    if (!data) {
                        return renderEmployeeDefaultAvatar(employee);
                    }
                    return (_jsx("img", { className: "w-[32px] h-[32px] rounded-full", src: data, alt: "" }));
                } }));
        }
        return renderEmployeeDefaultAvatar(employee);
    };
    return (_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "flex-none flex items-center border-b border-r border-gray-200 p-4 w-[356px] h-[56px] relative shadow-[7px_0px_16px_0px_#0000000F]" }, { children: [renderEmployeeAvatar(employee), _jsx("div", __assign({ className: "ml-2 text-sm leading-4 text-text-default" }, { children: employee.name })), _jsxs("div", __assign({ className: "flex w-[75px] h-[20px] p-2 gap-1 rounded-md ml-auto items-center bg-[#F3F4F6]" }, { children: [_jsx(AptiveIcon, { className: "w-[14px] h-[14px] stroke-black fill-none", icon: "clock" }), _jsxs("div", __assign({ className: "text-xs text-gray-500" }, { children: [employee.weekly_working_hours, " hrs"] }))] }))] })), dateRange.map(function (date, colIndex) {
                var formattedDate = format(date, 'yyyy-MM-dd');
                var holiday = getHoliday(formattedDate);
                if (holiday) {
                    return (_jsx("div", __assign({ className: "flex-1 h-[56px] px-1\n                ".concat(currentIndex === 0 && 'pt-1', "\n                ").concat(currentIndex === lastIndex && 'pb-1', "\n                ").concat(colIndex === 6 ? '' : 'border-r', "\n              ") }, { children: _jsx("div", __assign({ className: "\n                  p-2 h-full border-l border-r border-[#8E4B10] bg-[#FDFDEA] text-[#8E4B10]\n                  ".concat(currentIndex === 0 && 'border-t rounded-tl rounded-tr', "\n                  ").concat(currentIndex === lastIndex && 'border-b rounded-bl rounded-br', "\n                ") }, { children: currentIndex === 0 && (_jsx("div", __assign({ className: "text-xs leading-4 font-bold" }, { children: holiday.name }))) })) }), "".concat(employee.external_id, "_").concat(formattedDate)));
                }
                return (_jsx(PlanningCalendarShiftItem, { office: office, colIndex: colIndex, date: date, employee: employee, isProcessing: isProcessing, setIsProcessing: setIsProcessing, onToggleDateShift: function (date, isHidden) { return onToggleDateShift(employee, date, isHidden); }, onDeleteDateShift: onDeleteDateShift, onUpdateDateShift: onUpdateDateShift }, "".concat(employee.external_id, "_").concat(formattedDate)));
            })] })));
};
