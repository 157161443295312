// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n0VYAwniuO93xL0iDCXA {
  position: relative;
}
.n0VYAwniuO93xL0iDCXA:after {
  content: "";
  border-bottom: 2px solid #007AFF;
  position: absolute;
  bottom: -4px;
  width: 100%;
}

.k5hF9deEMsyAEqL2yi38 path {
  stroke: #007AFF;
}`, "",{"version":3,"sources":["webpack://./src/pages/MapView/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,WAAA;EACA,gCAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;AACJ;;AAIE;EACE,eAAA;AADJ","sourcesContent":[".activeTab {\n  position: relative;\n\n  &:after {\n    content: '';\n    border-bottom: 2px solid #007AFF;\n    position: absolute;\n    bottom: -4px;\n    width: 100%;\n  }\n}\n\n.activeSvg {\n  path {\n    stroke: #007AFF;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activeTab": `n0VYAwniuO93xL0iDCXA`,
	"activeSvg": `k5hF9deEMsyAEqL2yi38`
};
export default ___CSS_LOADER_EXPORT___;
