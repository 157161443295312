var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useRef, createContext } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { fetchPlanningWeeklyShifts } from 'services/planning';
import { format, startOfToday, startOfWeek } from 'date-fns';
import { Card } from '@aptive-env/storybook';
import { PlanningOfficeSelect } from 'components/Planning/PlanningOfficeSelect';
import { PlanningDateSelect } from 'components/Planning/PlanningDateSelect';
import { PlanningSummarySection } from 'components/Planning/PlanningSummarySection';
import { PlanningFooterSection } from 'components/Planning/PlanningFooterSection';
import { PlanningCalendar } from 'components/Planning/PlanningCalendar';
import { fetchRegions } from 'services/region';
import NoOffice from 'components/NoOffice';
import LoadingGif from 'assets/image/loading.gif';
export var AppContext = createContext({
    isGoogleMapAPILoaded: false,
    googleMapAPILoadError: undefined,
});
var libraries = ['drawing', 'places', 'marker'];
var Planning = function () {
    var _a, _b, _c, _d, _e;
    var _f = useState(true), isLoading = _f[0], setIsLoading = _f[1];
    var _g = useState(), data = _g[0], setData = _g[1];
    var _h = useState([]), regions = _h[0], setRegions = _h[1];
    var _j = useState(), office = _j[0], setOffice = _j[1];
    var _k = useState(startOfWeek(startOfToday(), { weekStartsOn: 0 })), startDate = _k[0], setStartDate = _k[1];
    var _l = useState(false), noOfficeModalOpen = _l[0], setNoOfficeModalOpen = _l[1];
    var fetchPlanningWeeklyShiftsControllerRef = useRef();
    var _m = useJsApiLoader({
        googleMapsApiKey: (_a = process.env.REACT_APP_GOOGLE_MAPS_API_KEY) !== null && _a !== void 0 ? _a : '',
        mapIds: [(_b = process.env.REACT_APP_GOOGLE_MAPS_ID) !== null && _b !== void 0 ? _b : ''],
        libraries: libraries,
    }), isLoaded = _m.isLoaded, loadError = _m.loadError;
    useEffect(function () {
        fetchRegions()
            .then(function (regions) {
            setRegions(regions);
            if (regions.length > 0) {
                setOffice(regions[0].offices[0]);
            }
            else {
                setNoOfficeModalOpen(true);
            }
        });
    }, []);
    useEffect(function () {
        if (fetchPlanningWeeklyShiftsControllerRef.current) {
            fetchPlanningWeeklyShiftsControllerRef.current.abort();
        }
        fetchPlanningWeeklyShiftsControllerRef.current = new AbortController();
        if (!office) {
            return;
        }
        setIsLoading(true);
        fetchPlanningWeeklyShifts({
            officeId: office.id,
            startDate: format(startDate, 'yyyy-MM-dd'),
        }, fetchPlanningWeeklyShiftsControllerRef.current.signal)
            .then(function (response) {
            if (response._metadata.success && response.result) {
                setData(response.result);
            }
            else {
                console.error('Got unexpected response from fetching planning weekly shifts');
            }
        })
            .catch(function (error) {
            if (error.code !== 'ERR_CANCELED') {
                console.error(error);
            }
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [office, startDate]);
    return (_jsx(AppContext.Provider, __assign({ value: { isGoogleMapAPILoaded: isLoaded, googleMapAPILoadError: loadError } }, { children: _jsxs("div", __assign({ className: "flex flex-col flex-grow overflow-hidden" }, { children: [_jsxs("div", __assign({ className: "flex items-center gap-6 p-6" }, { children: [_jsx(Card, __assign({ className: "flex-none w-[330px] bg-gray-50 border border-gray-200 rounded-lg h-[77px]" }, { children: _jsx(PlanningOfficeSelect, { regions: regions, office: office, onChangeOffice: setOffice, appContext: AppContext }) })), _jsx(Card, __assign({ className: "flex-grow w-full bg-white border border-gray-200 rounded" }, { children: _jsx(PlanningSummarySection, { startDate: startDate, summary: (_c = data === null || data === void 0 ? void 0 : data.summary) !== null && _c !== void 0 ? _c : null }) }))] })), _jsx("div", __assign({ className: "flex items-center gap-6 px-6" }, { children: _jsx(PlanningDateSelect, { startDate: startDate, onChangeStartDate: setStartDate }) })), isLoading ? (_jsx("div", __assign({ className: "flex-grow m-6 flex items-center justify-center border border-gray-200 rounded-tl bg-gray-50" }, { children: _jsx("div", __assign({ className: "w-[32px] h-[32px] rounded-full bg-gray-200 flex items-center justify-center" }, { children: _jsx("img", { src: LoadingGif, alt: "Loading ...", className: "w-[10px]" }) })) }))) : (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "flex-grow m-6 overflow-hidden" }, { children: _jsx(PlanningCalendar, { office: office, startDate: startDate, employees: (_d = data === null || data === void 0 ? void 0 : data.employees) !== null && _d !== void 0 ? _d : [], holidays: (_e = data === null || data === void 0 ? void 0 : data.holidays) !== null && _e !== void 0 ? _e : [] }) })), _jsx(PlanningFooterSection, {})] })), noOfficeModalOpen && (_jsx(NoOffice, { isOpen: noOfficeModalOpen, onClose: function () { return setNoOfficeModalOpen(false); } }))] })) })));
};
export default Planning;
