import { ComplexesTable, ComplexModal } from '../components/Complex';
import { CustomButton } from '@/components/common';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  requestTeamsStatisticsAsync,
  teamsStatisticsSelector,
  teamsStatisticsTotalSelector,
  requestComplexesStatisticsAsync,
  complexesStatisticsSelector,
  complexesStatisticsTotalSelector,
  requestComplexSummariesAsync,
  complexSummariesSelector,
  resetSelectedComplexAction,
  exportApartmentsComplexesAsync,
  resetSelectedApartmentAction,
} from '@/modules/Housing/redux/apartment';
import {
  isApartmentViewLoadingSelector,
  isArchiveComplexLoadingSelector,
} from '@/modules/Housing/redux/loading';
import {
  requestTeamsSummariesAsync,
  teamsSummariesSelector,
} from '@/modules/Housing/redux/area';
import {
  partnershipsSelector,
  requestPartnershipsAsync,
} from '@/modules/Housing/redux/partnership';
import {
  housingConstants,
  apartmentSetupConstants,
} from '@/modules/Housing/lib';
import {
  DropdownButton,
  AddFilterButton,
  FilterButton,
} from '@/modules/Housing/components/common';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { parseViewFilter } from '@/modules/Housing/modules/BedManagement/lib';
import { AdjustmentsIcon } from '@heroicons/react/outline';
import { countNumberOfFilters } from '@/modules/Housing/modules/BedsHistory/lib';
import { parseFilters } from '@/modules/Housing/modules/ApartmentSetup/lib';
import { useLocation } from 'react-router';
import { useFeatureFlag } from 'configcat-react';
import { ApartmentModal } from '../components/Apartment';
import {
  selectedComplexSelector,
  requestComplexAsync,
} from '@/modules/Housing/redux/apartment';

const {
  initialPage,
  initialPageSize,
  initialSearchText,
  initialSeasonPeriod,
  seasonOptions,
  APARTMENT_EXPORT_FEATURE_FLAG_NAME,
  FILTER_TYPE_MULTI_LIST,
} = housingConstants;

const {
  VIEW_BY_COMPLEX,
  VIEW_BY_TEAM,
  TEAMS_NAME,
  COMPLEXES_NAME,
  PARTNERSHIP_LABEL,
} = apartmentSetupConstants;

const downloadOptions = [
  {
    format: 'csv',
    label: 'Download CSV',
    action: exportApartmentsComplexesAsync.request,
  },
  {
    format: 'xlsx',
    label: 'Download Excel',
    action: exportApartmentsComplexesAsync.request,
  },
];

const ApartmentSetup = ({
  getTeamsStatistics,
  teamsStatistics,
  teamsStatisticsTotal,
  getComplexesStatistics,
  complexesStatistics,
  complexesStatisticsTotal,
  isApartmentViewLoading,
  isArchiveComplexLoading,
  resetSelectedComplex,
  requestComplexSummaries,
  requestTeamsSummaries,
  requestPartnerships,
  complexes,
  teams,
  partnerships,
  selectedComplex,
  getComplex,
  resetSelected,
}) => {
  const { value: isApartmentExportEnabled } = useFeatureFlag(
    APARTMENT_EXPORT_FEATURE_FLAG_NAME,
    false,
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [selectedPage, setSelectedPage] = useState(initialPage);
  const [selectedComplexId, setSelectedComplexId] = useState();
  const [isComplexOpen, setIsComplexOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(initialSearchText);
  const [seasonPeriod, setSeasonPeriod] = useState(initialSeasonPeriod);
  const [filterValueOptions, setFilterValueOptions] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [apartmentOpen, setApartmentOpen] = useState(false);

  const viewOptions = [
    {
      onClick: () => setFilterValueOptions(complexes),
      label: VIEW_BY_COMPLEX,
      value: COMPLEXES_NAME,
      type: 'dropdown',
    },
    {
      onClick: () => {
        setFilterValueOptions(
          teams.map((team) => ({ name: team.label, value: team.value }))
        );
      },
      label: VIEW_BY_TEAM,
      value: TEAMS_NAME,
      type: 'dropdown',
    },
  ];

  const viewOption = filters[0]?.type?.label ?? location.state?.viewOption ?? viewOptions[0].label;
  const count = countNumberOfFilters(selectedFilters);
  const partnershipOptions = useMemo(
    () =>
      partnerships.map((partner) => ({
        value: partner.name,
      })),
    [partnerships]
  );

  const filterOptions = [
    {
      name: PARTNERSHIP_LABEL,
      subMultiItems: partnershipOptions,
      type: FILTER_TYPE_MULTI_LIST,
    },
  ];

  useEffect(() => {
    requestPartnerships();
  }, [requestPartnerships]);

  useEffect(() => {
    if (!isArchiveComplexLoading) {
      getStatisticsData();
    }
  }, [
    getTeamsStatistics,
    getComplexesStatistics,
    pageSize,
    selectedPage,
    isArchiveComplexLoading,
  ]);

  useEffect(() => {
    if (selectedPage !== initialPage) {
      setSelectedPage(initialPage);
    } else if (!isArchiveComplexLoading) {
      getStatisticsData();
    }
  }, [
    viewOption,
    searchValue,
    seasonPeriod,
    filters,
    selectedFilters,
    isArchiveComplexLoading,
  ]);

  const getStatisticsData = () => {
    const params = {
      page: {
        size: pageSize,
        number: selectedPage,
      },
      ...(searchValue && { search_query: searchValue }),
      season_period: seasonPeriod,
      ...parseViewFilter(filters[0]),
      ...parseFilters(selectedFilters, partnerships),
    };

    if (viewOption === VIEW_BY_TEAM) {
      getTeamsStatistics(params);
    } else {
      getComplexesStatistics(params);
    }
  };

  useEffect(() => {
    requestComplexSummaries({
      season_period: seasonPeriod,
    });
    requestTeamsSummaries({
      season_period: seasonPeriod,
    });
  }, [seasonPeriod]);

  const onPageChange = useCallback(({ selected }) => {
    setSelectedPage(selected);
  }, []);

  const onComplexModalClose = useCallback(() => {
    resetSelectedComplex();
    setSelectedComplexId(null);
    setIsComplexOpen(false);
    getStatisticsData();
    requestTeamsSummaries({
      season_period: seasonPeriod,
    });
    requestPartnerships();
  }, [
    resetSelectedComplex,
    setSelectedComplexId,
    setIsComplexOpen,
    getStatisticsData,
  ]);

  const onEditClick = useCallback(
    (event, id) => {
      if (viewOption === VIEW_BY_COMPLEX) {
        setSelectedComplexId(id);
        setIsComplexOpen(true);
      }

      event.stopPropagation();
    },
    [viewOption]
  );

  const handleDownload = (option) => {
    if (option) {
      const params = {
        format: option.format,
        season_period: seasonPeriod,
        ...parseFilters(selectedFilters, partnerships),
        ...parseViewFilter(filters[0]),
      };
      dispatch(option.action(params));
    }
  };

  const onApartmentClose = useCallback(() => {
    setApartmentOpen(false);
    resetSelected();
    resetSelectedComplex();
  }, [setApartmentOpen, resetSelected]);

  const onAddApartmentClick = useCallback((evt, complexId) => {
    evt.stopPropagation();
    getComplex({
      complexId,
    });
    setApartmentOpen(true);
  }, [getComplex, setApartmentOpen]);

  return (
    <div className="grow p-6">
      <div className="mb-4 flex justify-between items-center">
        <span className="text-gray-900 text-[32px] font-normal leading-10">
          Apartments view
        </span>
        <div className="justify-end items-center gap-4 inline-flex">
          <AddFilterButton
            buttonClassName="px-2 py-1 rounded-2xl border border-gray-200 justify-start items-center gap-1 flex bg-white"
            label={viewOption}
            labelClassName="text-right text-xs font-normal font-['Inter'] leading-none"
            iconClassName="w-3 h-3 text-gray-900"
            filterTypeOptions={viewOptions}
            filterValueOptions={filterValueOptions}
            setFilters={setFilters}
            filters={filters}
            isSingleFilter={true}
          />
          <DropdownButton
            options={seasonOptions}
            label={
              seasonOptions.find((option) => option.value === seasonPeriod)
                .label
            }
            onChange={(option) => setSeasonPeriod(option.value)}
          />
          <FilterButton
            options={filterOptions}
            label={
              <div className="gap-1 inline-flex items-center">
                <AdjustmentsIcon className="w-3 h-3" />
                {count > 0 ? `Filters (${count})` : 'Filters'}
              </div>
            }
            setSelectedFilters={setSelectedFilters}
            selectedFilters={selectedFilters}
          />
          <CustomButton
            color="white"
            onClick={() => setIsComplexOpen(true)}
            className="px-2 py-1 rounded-2xl border border-gray-200 justify-start flex gap-1 items-center focus:ring-0"
          >
            <div className="text-right text-gray-900 text-xs font-normal font-['Inter'] leading-none">
              Add complex
            </div>
          </CustomButton>
          {isApartmentExportEnabled && (
            <DropdownButton
              options={downloadOptions}
              label="Download"
              onChange={handleDownload}
              dropdownPosition="left"
            />
          )}
        </div>
      </div>
      <ComplexesTable
        pageSize={pageSize}
        selectedPage={selectedPage}
        initialPage={initialPage}
        setPageSize={setPageSize}
        onPageChange={onPageChange}
        teamsStatistics={teamsStatistics}
        teamsStatisticsTotal={teamsStatisticsTotal}
        complexesStatistics={complexesStatistics}
        complexesStatisticsTotal={complexesStatisticsTotal}
        viewOption={viewOption}
        isLoading={isApartmentViewLoading || isArchiveComplexLoading}
        onEditClick={onEditClick}
        onAddApartmentClick={onAddApartmentClick}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        viewFilters={filters}
        setViewFilters={setFilters}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
      <ComplexModal
        isOpen={isComplexOpen}
        complexId={selectedComplexId}
        onClose={onComplexModalClose}
      />
      <ApartmentModal
        isOpen={apartmentOpen}
        complex={selectedComplex}
        onClose={onApartmentClose}
        apartmentId={null}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    teamsStatistics: teamsStatisticsSelector(state),
    teamsStatisticsTotal: teamsStatisticsTotalSelector(state),
    complexesStatistics: complexesStatisticsSelector(state),
    complexesStatisticsTotal: complexesStatisticsTotalSelector(state),
    isApartmentViewLoading: isApartmentViewLoadingSelector(state),
    isArchiveComplexLoading: isArchiveComplexLoadingSelector(state),
    complexes: complexSummariesSelector(state),
    teams: teamsSummariesSelector(state),
    partnerships: partnershipsSelector(state),
    selectedComplex: selectedComplexSelector(state),
  };
};

const mapDispatchToProps = {
  getTeamsStatistics: requestTeamsStatisticsAsync.request,
  getComplexesStatistics: requestComplexesStatisticsAsync.request,
  resetSelectedComplex: resetSelectedComplexAction,
  requestComplexSummaries: requestComplexSummariesAsync.request,
  requestTeamsSummaries: requestTeamsSummariesAsync.request,
  requestPartnerships: requestPartnershipsAsync.request,
  getComplex: requestComplexAsync.request,
  resetSelected: resetSelectedApartmentAction,
};

ApartmentSetup.propTypes = {
  getTeamsStatistics: PropTypes.func.isRequired,
  teamsStatistics: PropTypes.array,
  teamsStatisticsTotal: PropTypes.number,
  getComplexesStatistics: PropTypes.func.isRequired,
  complexesStatistics: PropTypes.array,
  complexesStatisticsTotal: PropTypes.number,
  isApartmentViewLoading: PropTypes.bool,
  resetSelectedComplex: PropTypes.func,
  isArchiveComplexLoading: PropTypes.bool,
  selectedComplex: PropTypes.object,
  getComplex: PropTypes.func,
  resetSelected: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApartmentSetup);
