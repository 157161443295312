import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router';
import { useEffect } from 'react';

import { requestPlansAsync } from '@/modules/AdminTools/redux/planBuilder/plans';
import { requestHistoryOptionsAsync } from '@/modules/AdminTools/redux/editHistory';

const EditHistory = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestPlansAsync.request());
    dispatch(requestHistoryOptionsAsync.request());
  }, [dispatch]);

  return (
    <div className="m-6">
      <Outlet />
    </div>
  );
};

export default EditHistory;
