// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gLm9FWI8Os9gRU0JeDC_ svg {
  width: 12px;
  height: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/Filters/index.module.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,YAAA;AAAJ","sourcesContent":[".dropdownButton {\n  svg {\n    width: 12px;\n    height: 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownButton": `gLm9FWI8Os9gRU0JeDC_`
};
export default ___CSS_LOADER_EXPORT___;
