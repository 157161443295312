import { settings as salesPlanningToolSettings } from '@aptive-env/soat-sales-planning-tool-ui';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import App from './App';
import { ConfigCatWrapper } from '@/configcat';
import { HandleCookies } from '@/lib/api';
import { configureStore, ProviderWrapper } from '@/redux/root';

salesPlanningToolSettings.configure({
  routePrefix: 'sales-planning-tool',
  apiUrl: 'https://spt-api.rep-services.stg.goaptive.com',
  JWT: () => HandleCookies.get('tokenPayload'),
});

const { store } = configureStore({});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ProviderWrapper store={store}>
        <ConfigCatWrapper>
          <App />
        </ConfigCatWrapper>
      </ProviderWrapper>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
