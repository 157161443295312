var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { PlanningCalendarHeader } from './PlanningCalendarHeader';
import { PlanningCalendarRow } from './PlanningCalendarRow';
import { format } from 'date-fns';
import { updatePlanningEmployeeShift } from 'services/planning';
export var PlanningCalendar = function (_a) {
    var startDate = _a.startDate, employees = _a.employees, holidays = _a.holidays, office = _a.office;
    var _b = useState(employees), mutableEmployees = _b[0], setMutableEmployees = _b[1];
    var _c = useState(false), isProcessing = _c[0], setIsProcessing = _c[1];
    var handleHideShowEmployeeDateShift = function (employee, date, isHidden) {
        setMutableEmployees(mutableEmployees.map(function (tempEmployee) {
            var newShifts = __assign({}, tempEmployee.shifts);
            if (tempEmployee.id === employee.id) {
                newShifts[format(date, 'yyyy-MM-dd')]['is_hidden'] = isHidden;
                return __assign(__assign({}, tempEmployee), { shifts: newShifts });
            }
            return tempEmployee;
        }));
    };
    var handleDeleteEmployeeDateShift = function (employee, date) {
        setMutableEmployees(mutableEmployees.map(function (tempEmployee) {
            var newShifts = __assign({}, tempEmployee.shifts);
            if (tempEmployee.id === employee.id) {
                newShifts[format(date, 'yyyy-MM-dd')]['shift_type'] = 'disabled';
                delete newShifts[format(date, 'yyyy-MM-dd')]['is_hidden'];
                return __assign(__assign({}, tempEmployee), { shifts: newShifts });
            }
            return tempEmployee;
        }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var handleUpdateEmployeeDateShift = useCallback(debounce(function (employee, date, shift) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!office) {
                        return [2 /*return*/];
                    }
                    setIsProcessing(true);
                    setMutableEmployees(mutableEmployees.map(function (tempEmployee) {
                        var _a;
                        if (tempEmployee.id === employee.id) {
                            return __assign(__assign({}, tempEmployee), { shifts: __assign(__assign({}, tempEmployee.shifts), (_a = {}, _a[format(date, 'yyyy-MM-dd')] = shift, _a)) });
                        }
                        return tempEmployee;
                    }));
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, updatePlanningEmployeeShift(employee.id, {
                            date: date,
                            officeId: office.id,
                            startAt: shift.start_at,
                            endAt: shift.end_at,
                            shiftType: shift.shift_type,
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error(error_1);
                    return [3 /*break*/, 4];
                case 4:
                    setIsProcessing(false);
                    return [2 /*return*/];
            }
        });
    }); }, 3000), [office, mutableEmployees]);
    return (_jsxs("div", __assign({ className: "flex flex-col border border-gray-200 rounded-tl h-full overflow-hidden" }, { children: [_jsx(PlanningCalendarHeader, { employees: mutableEmployees, startDate: startDate }), _jsx("div", __assign({ className: "flex-grow overflow-auto h-full" }, { children: mutableEmployees.map(function (employee, index) { return (_jsx(PlanningCalendarRow, { currentIndex: index, lastIndex: employees.length - 1, office: office, startDate: startDate, employee: employee, holidays: holidays, isProcessing: isProcessing, setIsProcessing: setIsProcessing, onToggleDateShift: handleHideShowEmployeeDateShift, onDeleteDateShift: function (date) { return handleDeleteEmployeeDateShift(employee, date); }, onUpdateDateShift: function (date, shift) { return handleUpdateEmployeeDateShift(employee, date, shift); } }, index)); }) }))] })));
};
