// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@tailwind base;
@tailwind components;
@tailwind utilities;
html, body, #root {
  height: 100%;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

*::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #9CA3AF;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

[hidden] {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./node_modules/tailwindcss/base.css","webpack://./node_modules/tailwindcss/components.css","webpack://./node_modules/tailwindcss/utilities.css","webpack://./src/pages/SalesRepresentatives/index.scss"],"names":[],"mappings":"AAAA,cAAA;ACAA,oBAAA;ACAA,mBAAA;ACIA;EACE,YAAA;AAAF;;AAGA;EAEC,mBAAA;EACA,6BAAA;AADD;;AAIA;EAEC,UAAA;EACA,6BAAA;AAFD;;AAKA;EAEC,mBAAA;EACA,yBAAA;AAHD;;AAMA,gDAAA;AACA;EACC,aAAA;AAHD;;AAMA,4CAAA;AACA;EACC,wBAAA,EAAA,gBAAA;EACC,qBAAA,EAAA,YAAA;AAHF;;AAMA;EACC,wBAAA;AAHD","sourcesContent":["@tailwind base;\n","@tailwind components;\n","@tailwind utilities;\n","@import 'tailwindcss/base';\n@import 'tailwindcss/components';\n@import 'tailwindcss/utilities';\n\nhtml, body, #root {\n  height: 100%;\n}\n\n*::-webkit-scrollbar-track\n{\n\tborder-radius: 10px;\n\tbackground-color: transparent;\n}\n\n*::-webkit-scrollbar\n{\n\twidth: 8px;\n\tbackground-color: transparent;\n}\n\n*::-webkit-scrollbar-thumb\n{\n\tborder-radius: 10px;\n\tbackground-color: #9CA3AF;\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.no-scrollbar::-webkit-scrollbar {\n\tdisplay: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.no-scrollbar {\n\t-ms-overflow-style: none;  /* IE and Edge */\n  scrollbar-width: none;  /* Firefox */\n}\n\n[hidden] {\n\tdisplay: none !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
